import { ArrowLeft, ArrowRight } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCombinedStore } from "../../../store";
import { CategoryMaster, Complain, ComplainUpdate } from "../../../swagger/models";
import { ListComplainParams } from "../../../swagger/models/list-complain-params";
import { calculateDaysElapsed, dateHelper, titleCase } from "../../common-module/helper";
import HowToFileAComplaint from "./HowToFileAComplaint";
import { saveAs } from 'file-saver'
import { toast } from "react-hot-toast";
import { ApiController } from "../../../apiController";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const COMPLAIN_STATUS = [
  {
    status: 'AWAITING_MII_REVIEW', // When Investor files the complain & waiting for MII to accept it
    icon: 'border-dashed border-status-orange border',
    subIcon: "",
    color: 'bg-status-white text-status-orange'
  },
  {
    status: 'DISPUTE_RESOLVED_-_PRECONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'CONCILIATION_INITIATED', // Conciliation started
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-grey text-status-green'
  },
  {
    status: 'CONCILIATION_IN_PROGRESS', // Conciliation In progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'CONCILIATION_FAILED', // Waitinf for party to start the arbitration process
    icon: "border-solid border-highlight-red border",
    subIcon: "",
    color: 'bg-status-fail-white text-highlight-red'
  },
  {
    status: 'ARBITRATION_REQUESTED', // Arbitration Requested by Investor
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_INITIATED', // Arbitration started
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_IN_PROGRESS', // Arbitration in progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'DISPUTE_RESOLVED_-_CONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'DISPUTE_RESOLVED_-_ARBITRATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  // {
  //   status: 'COMPLAINT_RESOLVED', // Complain got resolved
  //   icon: "border-solid border-highlight-blue border",
  //   subIcon: "",
  //   color: 'bg-light-blue text-highlight-blue'
  // },
  {
    status: 'DISPUTE_RETURNED', // When Complain is rejected by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
  {
    status: 'DISPUTE_WITHDRAWN', // When Complain is rejected by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
]

/**
 * ComplainList is a React component that manages the display and filtering of complaints.
 * It handles fetching and displaying a list of complaints, applying filters, and managing pagination.
 * Components and hooks from the react-router-dom, react-i18next, and various other libraries are utilized.
 *
 * Features:
 * - Fetches and displays complaints based on various filters including status, category, and date range.
 * - Provides the ability to search for disputes by ID.
 * - Offers pagination controls to navigate through the complaints.
 * - Includes functionality to download filtered complaints as an Excel file.
 * - Displays toast notifications if certain user requirements are not met.
 * - Supports different views for mobile and desktop browsers.
 *
 * Uses internal state to manage filters, pagination, and other UI elements.
 */
function ComplainList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const complaints: Complain[] = useCombinedStore((state: any) => state.complaints);
  const complainCount: number = useCombinedStore((state: any) => state.complainCount);
  const listComplaints = useCombinedStore((state: any) => state.listComplaints);
  const fetchCategoryMasters = useCombinedStore((state: any) => state.fetchCategoryMasters);

  const categoryMasters: CategoryMaster[] = useCombinedStore((state: any) => state.categoryMasters);
  const complainFilters = useCombinedStore((state: any) => state.complainFilters);
  const setComplainFilters = useCombinedStore((state: any) => state.setComplainFilters);
  const currentURLs = window.location.href.split('/')

  const [filter, setFilter] = useState('')
  const [searchClick, setSearchClick] = useState(false)
  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isPerPageOpen, setIsPerPageOpen] = useState(false)
  const dropdownRef = useRef<any>(null);
  const mobileDropdownRef = useRef<any>(null);
  const perPageRef = useRef<any>(null);

  const [complainParams, setComplaintParams] = useState<ListComplainParams>()
  const [selectedCategories, setSelectedCategories] = useState<CategoryMaster[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [complaintId, setComplaint] = useState<string>("");
  const [showGSTToast, setShowGSTToast] = useState(false);
  const [showPhoneToast, setShowPhoneToast] = useState(false);

  const delayedQuery = useRef(_.debounce((complainParams) => listComplaints(complainParams), 500)).current;

  const trackStatus = () => {
    navigate(`/${currentURLs[3]}/disputes/disputeDetails/${complaintId}`);
  }

  useEffect(() => {
    if (complainParams) {
      delayedQuery(complainParams);
    }
  }, [complainParams]);

  useEffect(() => {
    fetchCategoryMasters()
    setLimit(10)
    if (localStorage.getItem('selectedLanguage')) {
      i18n.changeLanguage(localStorage.getItem('selectedLanguage')!)
    }
    console.log(complainFilters)
    setSelectedCategories(complainFilters.selectedCategories)
    setSelectedStatus(complainFilters.selectedStatus)
    setPage(complainFilters.page)
    setLimit(complainFilters.limit)
    setComplaintParams({
      ...complainParams,
      fromDate: complainFilters.fromDate,
      toDate: complainFilters.toDate,
    })
  }, []);

  const searchDispute = (e: any) => {
    setComplaintParams({
      ...complainParams,
      search: e
    })
  }

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user && user.userType === 'Intermediary' && user.Organisation && user.RoleMaster) {
        if (!user.Organisation.gstNumber) {
          setShowGSTToast(true);
        } else {
          setShowGSTToast(false);
        }
        if (!user.Organisation.primaryContact) {
          setShowPhoneToast(true);
        } else {
          setShowPhoneToast(false);
        }
      }
    });
    return () => {
      toast.dismiss();
    };
  }, [])

  useEffect(() => {
    if (showGSTToast) {
      // Show the toast
      const toastId = toast.custom((to) => (
        <div
          className={`bg-warning flex w-1/2 flex-col px-6 py-4 shadow-md border-2 border-warning-b rounded-md ${to.visible ? 'animate-enter' : 'animate-leave'
            }`}
        >
          <i
            className="ph-bold ph-x justify-end w-full flex cursor-pointer"
            onClick={() => {
              toast.dismiss(toastId);
              // Update the state to prevent further rendering of the toast
              setShowGSTToast(false);
            }}
          ></i>
          <div className="flex sm:flex-row flex-col gap-4">
            <i className="ph-bold ph-warning text-warning-b text-5xl"></i>
            <p>
              <span className="font-bold">{t('UpdateGSTDetails')} </span>
              {t('UpdateGSTText')}
            </p>
          </div>
        </div>
      ),
        {
          duration: Infinity,
        });
    }
  }, [showGSTToast]);

  useEffect(() => {
    if (showPhoneToast) {
      // Show the toast
      const toastId = toast.custom((to) => (
        <div
          className={`bg-warning flex flex-col w-1/2 px-6 py-4 shadow-md border-2 border-warning-b rounded-md ${to.visible ? 'animate-enter' : 'animate-leave'
            }`}
        >
          <i
            className="ph-bold ph-x justify-end w-full flex cursor-pointer"
            onClick={() => {
              toast.dismiss(toastId);
              // Update the state to prevent further rendering of the toast
              setShowPhoneToast(false);
            }}
          ></i>
          <div className="flex flex-row gap-4">
            <i className="ph-bold ph-warning text-warning-b text-5xl"></i>
            <p>
              <span className="font-bold">{t('UpdatePhoneNumber')} </span> {t('UpdatePhoneText')}
            </p>
          </div>
        </div>
      ),
        {
          duration: Infinity,
        });
    }
  }, [showPhoneToast]);

  useEffect(() => {
    setComplaintParams({
      ...complainParams,
      limit: limit,
      status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
      category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
      offset: (page - 1) * limit,
      fromDate: complainParams?.fromDate,
      toDate: complainParams?.toDate
    })
    setComplainFilters(selectedCategories, selectedStatus, page, limit, complainParams?.fromDate, complainParams?.toDate)
  }, [selectedCategories, selectedStatus, page, limit, complainParams?.fromDate, complainParams?.toDate]);

  /**
   * 
   * @param categoryMaster 
   */
  const addCategoryFilter = (categoryMaster: CategoryMaster) => {
    const index = selectedCategories?.findIndex((i: CategoryMaster) => i.categoryId === categoryMaster.categoryId)
    if (index === -1) {
      setSelectedCategories([
        ...selectedCategories,
        categoryMaster
      ])
    }
  }
  /**
   * 
   * @param event 
   */
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target)) {
      setIsMobileOpen(false);
    }
    if (perPageRef.current && !perPageRef.current.contains(event.target)) {
      setIsPerPageOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleMobileDropdown = () => {
    setIsMobileOpen(!isMobileOpen);
  };
  const togglePerPage = () => {
    setIsPerPageOpen(!isPerPageOpen);
  };

  /**
   * 
   * @param status 
   */
  const addStatusFilter = (status: any) => {
    const index = selectedStatus?.findIndex((i: any) => i.status === status.status)
    if (index === -1) {
      setSelectedStatus([
        ...selectedStatus,
        status
      ])
    }
  }

  /**
   * Function to download the excel file of the selected status and category filters
   */
  function downloadExcel() {
    fetch(`${import.meta.env.VITE_BASE_PATH}/complain-management/complain_excel`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Disputes.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * Function to calculate the number of days passed
   * @param createdAt Dispute created date
   * @param status current status of the dispute
   * @param complainUpdates Array of object containing information about the complain updates
   * @returns number of days passed
   */
  function calculateDaysPassed(createdAt: any, status: string, complainUpdates: ComplainUpdate[], arbitrationExtension: any, conciliationExtension: any) {
    const { daysDifference, overDueDays } = calculateDaysElapsed(complainUpdates, status, createdAt, arbitrationExtension, conciliationExtension);
    return { daysDifference, overDueDays }
  }

  return (
    <div className="pt-7 w-full h-full sm:pr-4 pr-1 pl-1 pb-7">
      {complaints?.length === 0 && selectedCategories.length === 0 && selectedStatus.length === 0 && !complainParams?.fromDate && !complainParams?.toDate && !complainParams?.search ? (
        <HowToFileAComplaint />
      ) : (
        <>
          <div
            style={{ background: "#F4F4F4" }}
            className="hidden sm:flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl"
          >

            <p className="text-xs text-back-gray">{t('disputeHistory')}</p>
            <div className="flex flex-row gap-9 items-start">
              <h1 className="text-3xl whitespace-nowrap font-semibold text-highlight-blue pb-3">
                {t('disputeHistory')}
              </h1>
              <div className="relative" ref={dropdownRef}>
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="inline-flex ga[-2] items-center justify-center w-full px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                  id="dropdown-menu-button"
                  aria-expanded={isOpen}
                  aria-haspopup="true"
                >
                  <i className="ph-bold ph-funnel-simple pr-3"></i>
                  <p>{t('Filters')}</p>
                </button>
                {isOpen && (
                  <div
                    className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-menu-button"
                  >
                    <div className="py-1" role="none">
                      {filter === '' && (
                        <div className="flex flex-col gap-1 py-2 px-5 cursor-pointer">
                          <p className="text-xs cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('Status')}>{t('Status')}</p>
                          <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('Category')} >{t('Category')}</p>
                          <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('DateRange')} >{t('DateRange')}</p>
                        </div>
                      )}
                      {filter === "Status" &&
                        (
                          <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                            <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                            {COMPLAIN_STATUS?.map((status: any, index: number) => {
                              return (
                                <div key={status.status} className="flex flex-row gap-2 py-2 px-3 text-xs rounded-lg cursor-pointer hover:bg-white" onClick={() => addStatusFilter(status)}>
                                  <div className={`border relative w-7 rounded-full ${status.icon}`}>
                                    {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                      <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                    }
                                    {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                      <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                    }
                                  </div>
                                  <p className={`${status?.subIcon || ''} whitespace-nowrap`}>{titleCase(status.status)}</p>
                                </div>
                              )
                            })}
                          </div>
                        )
                      }
                      {filter === "Category" &&
                        (
                          <div className="px-4 py-2">
                            <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-white" onClick={() => setFilter('')} >{t('Back')}</p>
                            {categoryMasters?.map((categoryMaster: CategoryMaster) => {
                              return (
                                <div key={categoryMaster?.categoryId} className="flex flex-row gap-2 text-xs py-2 px-3 rounded-lg cursor-pointer  hover:bg-white" onClick={() => addCategoryFilter(categoryMaster)} >
                                  <i className="ph-bold ph-buildings text-sm"></i>
                                  <p>{categoryMaster?.categoryName}</p>
                                </div>
                              )
                            })}
                          </div>
                        )
                      }
                      {filter === "DateRange" &&
                        (
                          <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                            <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                              <div className="flex flex-row gap-2 items-center">
                                <label htmlFor="Date" className="text-xs">{t('FromDate')}</label>
                              </div>
                              <input type="date" name="FromDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={(e) => setComplaintParams({
                                ...complainParams,
                                fromDate: e.target.value
                              })} className="border rounded-lg border-back-gray w-full p-2" />
                            </div>
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                              <div className="flex flex-row gap-2 items-center">
                                <label htmlFor="Date" className="text-xs">{t('toDate')}</label>
                              </div>
                              <input type="date" name="toDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={(e) => setComplaintParams({
                                ...complainParams,
                                toDate: e.target.value
                              })} className="border rounded-lg border-back-gray w-full p-2" />
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-row gap-4 justify-end">
                <div className="relative" ref={perPageRef}>
                  <button
                    type="button"
                    onClick={togglePerPage}
                    className="inline-flex ga[-2] items-center justify-center w-full px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                    id="dropdown-menu-button"
                    aria-expanded={isPerPageOpen}
                    aria-haspopup="true"
                  >
                    <i className="ph-bold ph-arrows-down-up pr-3"></i>
                    <p>{t('PerPage')}: {limit}</p>
                  </button>
                  {isPerPageOpen && (
                    <div
                      className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-menu-button"
                    >
                      <div className="py-1" role="none">
                        <div className="flex flex-col gap-1 py-2 px-5 cursor-pointer">
                          <p className="text-xs cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setLimit(10)}>{t('10disputes')}</p>
                          <p className="text-xs cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setLimit(20)}>{t('20disputes')}</p>
                          <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setLimit(50)} >{t('50disputes')}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <button onClick={downloadExcel} className="text-xs px-4 py-2 rounded-xl border border-border-grey bg-EBEBEB hover:bg-white">{t('ExportExcel')}</button>
                <div className="flex flex-row w-1/3 gap-2 px-3 py-1 bg-white rounded-xl items-center">
                  <input name='complaintId' placeholder={t('SearchDisputeID')} id='complaintId' onChange={(e) => searchDispute(e.target.value)} required aria-label={t('TrackStatus')} type="text" className='rounded-lg text-xs w-full p-2 border-border-grey border-solid' />
                  <i onClick={trackStatus} className="ph-bold ph-magnifying-glass cursor-pointer hover:text-black/20"></i>
                </div>
              </div>
            </div>
            <div>
              {(selectedCategories.length !== 0 || selectedStatus.length !== 0 || complainParams?.fromDate || complainParams?.toDate) && (
                <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">

                  {selectedCategories.length !== 0 && (
                    <>
                      {selectedCategories?.map((category: CategoryMaster) =>
                      (
                        <div key={category?.categoryId}>
                          <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                            <p>{t('Category')}</p>
                            <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                              <i className="ph-bold ph-buildings text-sm"></i>
                              <p>{category?.categoryName}</p>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </>
                  )}
                  {selectedStatus.length > 0 && (
                    <>
                      {selectedStatus?.map((status: any) =>
                      (
                        <div key={status?.status}>
                          <div className="text-xs h-full bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                            <p>{t('Status')}</p>
                            <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2">
                              <div className={`border w-7 rounded-full ${status.icon}`}>
                                {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-conc-green"></div>
                                }
                                {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-highlight-blue"></div>
                                }
                              </div>
                              <p className={`${status?.subIcon || ''}`}>{titleCase(status.status)}</p>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </>
                  )}
                  {complainParams?.fromDate && (
                    <div>
                      <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                        <p>{t('FromDate')}</p>
                        <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                          <i className="ph-bold ph-calendar text-sm"></i>
                          <p>{dateHelper(complainParams?.fromDate)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {complainParams?.toDate && (
                    <div>
                      <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                        <p>{t('toDate')}</p>
                        <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                          <i className="ph-bold ph-calendar text-sm"></i>
                          <p>{dateHelper(complainParams?.toDate)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-fit px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                    id="dropdown-menu-button"
                    onClick={() => {
                      setSelectedCategories([])
                      setSelectedStatus([])
                      setFilter('')
                      setComplaintParams({
                        ...complainParams,
                        fromDate: undefined,
                        toDate: undefined
                      })
                    }}
                  >
                    <i className="ph-bold ph-x pr-2"></i>
                    <p className="text-xs">{t('Clear')}</p>

                  </button>
                </div>
              )}
            </div>


            <table className="table-auto w-full">
              <thead>
                <tr>
                  {/* <th
                className="border-b p-3 font-normal text-left"
                style={{ borderColor: "#EBEBEB" }}
              >
                <input type="checkbox" name="selectAll" />
              </th> */}
                  {currentURLs.length > 0 && (currentURLs[3] === 'mii' || currentURLs[3] === 'odr') && (
                    <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                      {t('Dispute Title')}
                    </th>
                  )}
                  {currentURLs.length > 0 && (currentURLs[3] === 'investor') && (
                    <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                      {t('disputeFiledAgainst')}
                    </th>
                  )}
                  {currentURLs.length > 0 && (currentURLs[3] === 'intermediary') && (
                    <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                      {t('Dispute Filed By')}
                    </th>
                  )}
                  <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                    {t('Dispute ID')}
                  </th>
                  <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                    {t('Dispute Status')}
                  </th>
                  <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                    {t('Category')}
                  </th>
                  <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                    {t('Days Elapsed')}
                  </th>
                  {currentURLs.length > 0 && (currentURLs[3] !== 'investor') &&
                    <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                      {t('Region')}
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                {complaints?.map((complaint: Complain) => {
                  const statusObject = COMPLAIN_STATUS.find((statusObj) => statusObj.status === complaint?.status);
                  const { daysDifference, overDueDays } = calculateDaysPassed(
                    complaint?.createdAt,
                    complaint?.status,
                    complaint?.ComplainUpdates,
                    complaint?.arbitrationExtension,
                    complaint?.conciliationExtension
                  );
                  return (
                    <tr key={complaint?.complainId}>
                      {/* <td className="border-b p-3">
                    <label htmlFor={`checkbox`}>
                      <input type="checkbox" id={`checkbox`} />
                    </label>
                  </td> */}
                      {currentURLs.length > 0 && (currentURLs[3] === 'investor') && (
                        <td className="border-b p-3 font-normal text-xs">
                          <div className="flex gap-1 items-center">
                            <Link to={`./disputeDetails/${complaint?.complaintUUID}`}>{complaint?.respondentName}</Link>
                            {complaint?.isFromScores &&
                              <i className="ph-bold ph-arrow-circle-right text-back-gray"></i>
                            }
                          </div>
                        </td>
                      )}
                      {currentURLs.length > 0 && (currentURLs[3] === 'mii' || currentURLs[3] === 'odr') && (
                        <td className="border-b p-3 font-normal text-xs">
                          <div className="flex gap-1 items-center">
                            <Link to={`./disputeDetails/${complaint?.complaintUUID}`}>{complaint?.complainantName} {t('vs')} {complaint?.respondentName}</Link>
                            {complaint?.isFromScores &&
                              <i className="ph-bold ph-arrow-circle-right text-back-gray"></i>
                            }
                          </div>
                        </td>
                      )}
                      {currentURLs.length > 0 && (currentURLs[3] === 'intermediary') && (
                        <td className="border-b p-3 font-normal text-xs">
                          <div className="flex gap-1 items-center">
                            <Link to={`./disputeDetails/${complaint?.complaintUUID}`}>{complaint?.complainantName}</Link>
                            {complaint?.isFromScores &&
                              <i className="ph-bold ph-arrow-circle-right text-back-gray"></i>
                            }
                          </div>

                        </td>
                      )}
                      <td className="border-b p-3 font-normal text-xs">
                        <Link to={`./disputeDetails/${complaint?.complaintUUID}`}>{complaint?.complaintUUID}</Link>
                      </td>
                      <td className="border-b p-3 font-normal text-xs">
                        <div className={`py-2 w-fit px-4 border-2 rounded-2xl ${statusObject?.color} ${statusObject?.icon}`}>
                          <p className="font-bold text-center whitespace-nowrap">{titleCase(complaint?.status)}</p>
                        </div>
                      </td>
                      <td className="border-b p-3 font-normal text-xs">
                        {complaint?.SubCategoryMaster?.categoryName}
                      </td>
                      <td className="border-b p-3 font-normal text-xs">
                        {daysDifference} {overDueDays > 0 &&
                        <Tooltip
                          title={t('overDueBy', {overDueDays})}
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({ children: <span className="hover:cursor-pointer text-highlight-red">({overDueDays})</span> } as any)} // Type assertion to bypass TS errors
                        >
                        </Tooltip>
                        }
                      </td>
                      {currentURLs.length > 0 && (currentURLs[3] !== 'investor') &&
                        <td className="border-b p-3 font-normal text-xs">
                          {complaint?.respondentType === "Investor" ? (complaint?.RespondentUser?.city || "N/A") : (complaint?.Complainant?.city || "N/A")}
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {page >= 1 && (
              <div className="flex flex-row gap-2 items-end justify-end mb-2">
                {page > 1 &&
                  <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
                }
                <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
                {complaints?.length >= limit &&
                  <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
                }
              </div>
            )}
            <p className="text-xs text-back-gray text-center pt-8">{limit * (page - 1) + complaints?.length} of {complainCount}</p>
            {currentURLs.length > 0 && currentURLs[3] === 'intermediary' &&
              (<p className="text-xs text-back-gray text-center pt-8">{t('YourOpinion')} {t('Click')} <a href="https://forms.gle/sssjMzZBXgCFXNVRA" target="_blank" style={{ color: '#306AFF' }} className="hover:underline">{t('here')}</a> {t('ShareThoughts')}</p>)
            }
          </div>
          <div style={{ background: "#F4F4F4" }} className="sm:hidden flex flex-initial flex-col gap-5 w-full h-full px-8 py-7 rounded-2xl">
            {searchClick ? (
              <div className="flex flex-row w-full gap-2 px-3 py-1 bg-white rounded-xl items-center">
                <input name='complaintId' placeholder={t('SearchDisputeID')} id='complaintId' onChange={(e) => searchDispute(e.target.value)} required aria-label={t('TrackStatus')} type="text" className='rounded-lg text-xs w-full p-2 border-border-grey border-solid' />
                <i onClick={trackStatus} className="ph-bold ph-x cursor-pointer hover:text-black/20"></i>
              </div>
            ) : (
              <div className="flex flex-row justify-between pb-3 border-b border-white items-center">
                <h1 className="text-lg font-semibold text-highlight-blue ">
                  {t('Dispute History')}
                </h1>
                {/* <div className="flex flex-row w-1/5 gap-2 px-3 py-1 bg-white rounded-xl items-center">
                <input name='complaintId' placeholder={t('SearchDisputeID')} id='complaintId' onChange={(e) => searchDispute(e.target.value)} required aria-label={t('TrackStatus')} type="text" className='rounded-lg text-xs w-full p-2 border-border-grey border-solid' />
                <i onClick={trackStatus} className="ph-bold ph-magnifying-glass cursor-pointer hover:text-black/20"></i>
              </div> */}
                <div className="flex flex-row gap-2 items-center">
                  <button onClick={downloadExcel} className="text-lg px-1 rounded-xl border border-border-grey bg-EBEBEB hover:bg-white"><i className="ph ph-microsoft-excel-logo"></i></button>
                  <i onClick={() => setSearchClick(!searchClick)} className="ph-bold ph-magnifying-glass cursor-pointer hover:text-black/20"></i>
                  <div className="relative" ref={mobileDropdownRef}>
                    <button
                      type="button"
                      onClick={toggleMobileDropdown}
                      className=" items-center justify-center w-fit  text-sm font-medium text-label-grey"
                      id="dropdown-menu-button"
                      aria-expanded={isMobileOpen}
                      aria-haspopup="true"
                    >
                      <i className="ph-bold ph-funnel-simple pr-3"></i>
                    </button>
                    {isMobileOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="dropdown-menu-button"
                      >
                        <div className="py-1" role="none">
                          {filter === '' && (
                            <div className="flex flex-col gap-1 py-2 px-5 cursor-pointer">
                              <p className="text-xs cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('Status')}>{t('Status')}</p>
                              <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('Category')} >{t('Category')}</p>
                              <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('DateRange')} >{t('DateRange')}</p>
                            </div>
                          )}
                          {filter === "Status" &&
                            (
                              <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                                <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                                {COMPLAIN_STATUS?.map((status: any, index: number) => {
                                  return (
                                    <div key={status.status} className="flex flex-row gap-2 py-2 px-3 text-xs rounded-lg cursor-pointer  hover:bg-white" onClick={() => addStatusFilter(status)}>
                                      <div className={`border relative w-7 rounded-full ${status.icon}`}>
                                        {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                          <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                        }
                                        {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                          <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                        }
                                      </div>
                                      <p className={`${status?.subIcon || ''} whitespace-nowrap`}>{titleCase(status.status)}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          }
                          {filter === "Category" &&
                            (
                              <div className="px-4 py-2">
                                <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-white" onClick={() => setFilter('')} >{t('Back')}</p>
                                {categoryMasters?.map((categoryMaster: CategoryMaster) => {
                                  return (
                                    <div key={categoryMaster?.categoryId} className="flex flex-row gap-2 text-xs py-2 px-3 rounded-lg cursor-pointer  hover:bg-white" onClick={() => addCategoryFilter(categoryMaster)} >
                                      <i className="ph-bold ph-buildings text-sm"></i>
                                      <p>{categoryMaster?.categoryName}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          }
                          {filter === "DateRange" &&
                            (
                              <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                                <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                                  <div className="flex flex-row gap-2 items-center">
                                    <label htmlFor="Date" className="text-xs">{t('FromDate')}</label>
                                  </div>
                                  <input type="date" name="FromDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={(e) => setComplaintParams({
                                    ...complainParams,
                                    fromDate: e.target.value
                                  })} className="border rounded-lg border-back-gray w-full p-2" />
                                </div>
                                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                                  <div className="flex flex-row gap-2 items-center">
                                    <label htmlFor="Date" className="text-xs">{t('toDate')}</label>
                                  </div>
                                  <input type="date" name="toDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={(e) => setComplaintParams({
                                    ...complainParams,
                                    toDate: e.target.value
                                  })} className="border rounded-lg border-back-gray w-full p-2" />
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>
              {(selectedCategories.length !== 0 || selectedStatus.length !== 0 || complainParams?.fromDate || complainParams?.toDate) && (
                <div className="grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 gap-4">

                  {selectedCategories.length !== 0 && (
                    <>
                      {selectedCategories?.map((category: CategoryMaster) =>
                      (
                        <div key={category?.categoryId}>
                          <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                            <p>{t('Category')}</p>
                            <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                              <i className="ph-bold ph-buildings text-sm"></i>
                              <p>{category?.categoryName}</p>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </>
                  )}
                  {selectedStatus.length > 0 && (
                    <>
                      {selectedStatus?.map((status: any) =>
                      (
                        <div key={status?.status}>
                          <div className="text-xs h-full bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                            <p>{t('Status')}</p>
                            <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2">
                              <div className={`border w-7 rounded-full ${status.icon}`}>
                                {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-conc-green"></div>
                                }
                                {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-highlight-blue"></div>
                                }
                              </div>
                              <p className={`${status?.subIcon || ''}`}>{titleCase(status.status)}</p>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </>
                  )}
                  {complainParams?.fromDate && (
                    <div>
                      <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                        <p>{t('FromDate')}</p>
                        <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                          <i className="ph-bold ph-calendar text-sm"></i>
                          <p>{dateHelper(complainParams?.fromDate)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {complainParams?.toDate && (
                    <div>
                      <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                        <p>{t('toDate')}</p>
                        <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                          <i className="ph-bold ph-calendar text-sm"></i>
                          <p>{dateHelper(complainParams?.toDate)}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-fit px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                    id="dropdown-menu-button"
                    onClick={() => {
                      setSelectedCategories([])
                      setSelectedStatus([])
                      setFilter('')
                      setComplaintParams({
                        ...complainParams,
                        fromDate: undefined,
                        toDate: undefined
                      })
                    }}
                  >
                    <i className="ph-bold ph-x pr-2"></i>
                    <p className="text-xs">{t('Clear')}</p>

                  </button>
                </div>
              )}
            </div>
            {complaints?.map((complaint: Complain) => {
              const statusObject = COMPLAIN_STATUS.find((statusObj) => statusObj.status === complaint?.status);
              return (
                <Link to={`./disputeDetails/${complaint?.complaintUUID}`} key={complaint?.complaintUUID} className="flex flex-row justify-between w-full items-end">
                  <div className="flex flex-row gap-3">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm">{complaint?.respondentName}</p>
                      <p className="text-[10px] text-border-grey">{complaint?.complaintUUID}</p>
                    </div>


                  </div>
                  <div className="flex gap-4">
                    <div className={`py-1 w-fit px-1.5 border-2 rounded-2xl h-fit ${statusObject?.color} ${statusObject?.icon}`}>
                      <p className="font-bold text-center text-[10px] whitespace-nowrap">{titleCase(complaint?.status)}</p>
                    </div>
                    <i className="ph-bold ph-caret-right"></i>
                  </div>
                </Link>

              )
            })}
            {page >= 1 && (
              <div className="flex flex-row gap-2 items-end justify-end mb-2">
                {page > 1 &&
                  <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
                }
                <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
                {complaints?.length >= limit &&
                  <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
                }
              </div>
            )}
          </div>
        </>
      )}

    </div>
  );
}

export default ComplainList;
