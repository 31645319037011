import { Link } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useCombinedStore } from "../../../store"
import { MarketParticipantApproval, MarketParticipantRejection, Organisation, OrganisationParent, SubCategoryMaster } from "../../../swagger/models"
import { CityType, StateType } from '../../common-module/helper'
import { useTranslation } from 'react-i18next';

function MarketParticipantDetails() {
  const { t } = useTranslation();
  const [orgDetail, setOrgDetails] = useState<Organisation>({
    country: 'India',
    OrganisationParents: []
  })
  const [selectedMIIs, setSelectedMIIs] = useState<Organisation[]>([])

  const params = useParams()
  const orgId: string = params.orgId!
  const role: string = params.role!

  const orgProfile = useCombinedStore((state: any) => state.orgDetail)
  const getOrganisation = useCombinedStore((state: any) => state.getOrganisation)
  const subCategoryMasters = useCombinedStore((state: any) => state.subCategoryMasters)
  const listSubCategoryMasters = useCombinedStore((state: any) => state.listSubCategoryMasters)
  const listOrganisations = useCombinedStore((state: any) => state.listOrganisations)
  const approveMarketParticipant = useCombinedStore((state: any) => state.approveMarketParticipant);
  const rejectMarketParticipant = useCombinedStore((state: any) => state.rejectMarketParticipant);

  const organisations: Organisation[] = useCombinedStore((state: any) => state.organisations);
  const currentURL = window.location.href.split('/')[3]
  const addURL = window.location.href.split('/')[6]

  const [rejectionModal, setRejectionModal] = useState(false)
  const [approvalnModal, setApprovalModal] = useState(false)
  const [mpRejection, setMpRejection] = useState<MarketParticipantRejection>({})
  const [mpApproval, setMpApproval] = useState<MarketParticipantApproval>({})

  useEffect(() => {
    if (orgId) {
      console.log(orgId)
      getOrganisation(orgId);
    } else {
      setOrgDetails({
        country: 'India',
        OrganisationParents: []
      })
    }
    setOrgDetails({
      ...orgDetail,
      organisationType: role?.toUpperCase(),
    })
  }, [orgId]);

  useEffect(() => {
    if (orgProfile && orgId) {
      setOrgDetails(orgProfile)
      setSelectedMIIs(orgProfile?.OrganisationParents?.map((i: OrganisationParent) => ({
        orgId: i.parentId,
      })))
    }
  }, [orgProfile])

  useEffect(() => {
    listSubCategoryMasters()
    listOrganisations({
      orgType: 'MII',
      limit: 10,
      offset: 0,
    });
  }, [])

  const rejectMPApplication = (e: any) => {
    e.preventDefault();
    rejectMarketParticipant(mpRejection)
    setRejectionModal(false)
  };

  const approveMP = () => {
    approveMarketParticipant(mpApproval)
    setApprovalModal(false)
  }

  const approveMPAction = () => {
    setMpApproval({
      orgIds: [JSON.stringify(orgId)]
    })
    setApprovalModal(true)
  }

  const performAction = () => {
    setMpRejection({
      ...mpRejection,
      orgId: orgId
    })
    setRejectionModal(true)
  }

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full flex-initial  px-8 py-7 flex-col gap-2  rounded-2xl">
        <p className='text-xs sm:block hidden text-back-gray'>
          <NavLink to={`/${currentURL}/settings`}> {t('Settings')} </NavLink> &gt;
          <NavLink to={`/${currentURL}/settings/organisation-management/intermediary`}>{t("UserManagement")}</NavLink>
        </p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t('Back')}</Link>
          {orgProfile && <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{orgProfile?.organisationName}</p>
              <p className="text-xs text-back-gray">{orgProfile?.email}</p>
            </div>
          </div>}
          <form>
            <div className="flex flex-col gap-2 pl-7 w-1/2">
              <label htmlFor="organisationName" className="text-xs">{t('OrganisationName')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.organisationName} required aria-label={t('OrganisationName')} type="text" name='organisationName' id='organisationName' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="primaryContactName" className="text-xs">{t('PrimaryContact')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.primaryContactName} required aria-label={t('PrimaryContact')} type="text" name='primaryContactName' id='primaryContactName' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="primaryEmail" className="text-xs">{t('PrimaryEmailID')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.primaryEmail} required aria-label={t('PrimaryEmailID')} type="email" name='primaryEmail' id='primaryEmail' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="primaryContact" className="text-xs">{t('PrimaryPhoneNumber')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.primaryContact} required aria-label={t('PrimaryPhoneNumber')} type="text" name='primaryContact' id='primaryContact' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="PAN" className="text-xs">{t("PANNumber")}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.panNumber} aria-label={t("PANNumber")} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="uniqueSEBIId" className="text-xs">{t("UniqueSEBIId")}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.uniqueSEBIId} aria-label={t("UniqueSEBIId")} type="text" name='uniqueSEBIId' id='uniqueSEBIId' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="GST" className="text-xs">{t('GSTNumber')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.gstNumber} aria-label={t('GSTNumber')} type="text" name='gstNumber' id='gstNumber' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="ISINNumber" className="text-xs">{t('ISINNumber')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.ISINNumber} aria-label={t('ISINNumber')} type="text" name='ISINNumber' id='ISINNumber' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="incorporationNumber" className="text-xs">{t('InCorporationNumber')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.incorporationNumber} aria-label={t('InCorporationNumber')} type="text" name='incorporationNumber' id='incorporationNumber' className={`rounded-xl border p-2 border-solid border-border-grey`} />

              <label htmlFor="registeredAddress" className="text-xs">{t('Address')}</label>
              <input disabled={orgDetail?.orgId} value={orgDetail?.registeredAddress} required aria-label={t('Address')} type="text" name='registeredAddress' id='registeredAddress' className={`rounded-xl border p-2 border-solid border-border-grey`} />
              <label htmlFor="State" className="text-xs">{t('State')}</label>

              <select
                disabled={orgDetail?.orgId}
                aria-label={t('SelectState')} required name='state' id='state'
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.state}
              >
                <option>{t('SelectState')}</option>
                {StateType?.map((state: string) => {
                  return <option key={state} value={state}>{state}</option>
                })}
              </select>
              <div className="flex flex-row justify-between">
                <div className="w-1/2 flex flex-col gap-2 pr-4">
                  <label htmlFor="City" className="text-xs">{t('City')}</label>
                  <select
                    disabled={orgDetail?.orgId}
                    aria-label={t('SelectCity')} required name='city' id='city'
                    className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.city}
                  >
                    <option>{t('Select City')}</option>
                    {CityType.filter((city: any) => city.state === orgDetail?.state)?.map((city: any, index: number) => {
                      return <option key={`${city.city}-${index}`} value={city.city}>{city.city}</option>
                    })}
                  </select>

                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <label htmlFor="Pincode" className="text-xs">{t('Pincode')}</label>
                  <input disabled={orgDetail?.orgId} value={orgDetail?.pinCode} required aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`rounded-lg border p-2 border-solid border-border-grey`} />
                </div>
              </div>
              {currentURL === 'mii' &&
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-2 pr-4">
                    <p className="text-xs">{t('SelectExchangeDepository')} <span className="text-xs text-highlight-red">*</span></p>
                    {/* Show list of all the MII */}
                    {organisations?.map((organisation: Organisation, index: number) => {
                      return <div key={organisation?.orgId} className="flex flex-col gap-2">
                        <div className='flex gap-2 items-center'>
                          <input type="checkbox"
                            disabled={orgDetail?.orgId}
                            name={organisation?.orgId}
                            checked={selectedMIIs?.some(i => i.orgId === organisation.orgId)}
                          />
                          <label htmlFor={organisation?.organisationName} className="text-xs">{organisation?.organisationName}</label>
                        </div>
                        {/* Show list of all the categories if MII is selected */}
                        {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === organisation.orgId) &&
                          <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                            <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                            {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId === 1 || category.subCategoryId === 4 || category.subCategoryId === 6)?.map((category: SubCategoryMaster, index: number) => {
                              return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                                <input type="checkbox"
                                  disabled={orgDetail?.orgId}
                                  name={category?.subCategoryId}
                                  checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === organisation.orgId && op.subCategoryId === category?.subCategoryId)}
                                />
                                <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                              </div>
                            })}
                          </div>
                        }
                      </div>
                    })}
                    <div className="flex flex-col gap-2">
                      <div className='flex gap-2 items-center'>
                        <input type="checkbox"
                          name='N/A'
                          disabled={orgDetail?.orgId}
                          checked={selectedMIIs?.some(i => i.orgId === null)}
                        />
                        <label htmlFor='N/A' className="text-xs">N/A (Not Applicable)</label>
                      </div>
                      {/* Show list of all the categories if MII is selected */}
                      {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === null) &&
                        <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                          <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                          {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId !== 1 && category.subCategoryId !== 4 && category.subCategoryId !== 6)?.map((category: SubCategoryMaster, index: number) => {
                            return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                              <input type="checkbox"
                                disabled={orgDetail?.orgId}
                                name={category?.subCategoryId}
                                checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === null && op.subCategoryId === category?.subCategoryId)}
                              />
                              <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                            </div>
                          })}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button className="hover:bg-highlight-green/[0.4] bg-highlight-green items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 
              rounded-lg disabled:bg-highlight-green/[0.4]"
                onClick={() => approveMPAction()}
                aria-label={t('Save')}>
                {t('Approve')}
              </button>
              <button onClick={() => performAction()} aria-label={t('Reject')} className=" hover:bg-black/[0.4] text-xs text-back-gray px-8 py-2.5 rounded-lg">
                {t('Reject')}
              </button>

            </div>
          </form>
          <p className="pb-3 text-xs pl-7"></p>
        </div>
      </div>
      {rejectionModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setRejectionModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white border-2 border-highlight-red rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('RejectionHeader')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('RejectedSubHeader')}</p>
                        <textarea onChange={(e) => setMpRejection({
                          ...mpRejection,
                          rejectionReason: e.target.value
                        })} placeholder={t('Reasons')} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                        <p className="text-xs text-label-grey">{t('RejectionNote1')}</p>
                        <p className="text-xs text-label-grey">{t('RejectionNote2')}</p>
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                        <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Submit')}
                          onClick={rejectMPApplication}>
                          <p>{t('Proceed')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setRejectionModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {approvalnModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setApprovalModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white border-2 border-resolved-green rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('ApprovalHeader')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('ApprovalSubHeader')}</p>
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                        <button className="hover:bg-highlight-green/[0.4] bg-highlight-green justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-green/[0.4] px-12" aria-label={t('Submit')}
                          onClick={approveMP}>
                          <p>{t('Proceed')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setApprovalModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MarketParticipantDetails