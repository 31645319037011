import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCombinedStore } from "../../../store";
import { ListUserParams, User } from "../../../swagger/models";
import { titleCase } from "../../common-module/helper";
import _ from "lodash";
import { Tooltip } from "react-tippy";
import { ArrowLeft, ArrowRight } from "phosphor-react";
import { useTranslation } from 'react-i18next';

/**
 * The UserManagementSettings component is used to display the list of users within an organization.
 * It also allows the user to search for users, resend verification links, and delete users.
 * The component is only accessible by an organization admin.
 * 
 * @returns The UserManagementSettings component
 */
function UserManagementSettings() {
    const searchParams = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const users: User[] = useCombinedStore((state: any) => state.users);
    const listUsers = useCombinedStore((state: any) => state.listUsers);
    const adminCount = useCombinedStore((state: any) => state.adminCount);
    const resendUserInvitation = useCombinedStore((state: any) => state.resendUserInvitation);
    const deleteUserProfile = useCombinedStore((state: any) => state.deleteUserProfile);
    const currentURL = window.location.href.split('/')[3]
    const [userParams, setUserParams] = useState<ListUserParams>({});
    const delayedQuery = useRef(_.debounce((userParams) => listUsers(userParams), 500)).current;
    const role = searchParams.get('role') || currentURL
    const orgId = searchParams.get('orgId') || undefined

  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const searchUser = (e: any) => {
    setUserParams({
      ...userParams,
      search: e,
    });
  };

  useEffect(() => {
    console.log(userParams)
    delayedQuery(userParams);
  }, [userParams]);

  const resendInvitation = (userId: number) => {
    resendUserInvitation(userId);
  };

  const deleteUser = (userId: number) => {
    deleteUserProfile(userId);
  };

  useEffect(() => {
    switch (role) {
      case "investor":
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          role: "Investor",
        });
        break;
      case "sebi":
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          orgId: orgId,
          role: "SEBI",
        });
        break;
      case "odr":
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          orgId: orgId,
          role: "ODR",
        });
        break;
      case "mii":
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          orgId: orgId,
          role: "MII",
        });
        break;
      case "intermediary":
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          orgId: orgId,
          role: "INTERMEDIARY",
        });
        break;
      default:
        setUserParams({
          ...userParams,
          limit: limit,
            offset: (page - 1) * limit,
          role: "Investor",
        });
        break;
    }
  }, [role, page, limit]);

    return (
        <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
            <div
                style={{ background: '#F4F4F4' }}
                className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
                <p className='hidden sm:block text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('UserManagement')}</p>
                <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
                <div className="bg-white w-full h-full flex flex-col rounded-xl gap-7">
                    <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t('Back')}</Link>
                    <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
                        <i className="ph-bold ph-user-circle-plus sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                            <p className="sm:text-sm text-xs">{t('UserManagement')}</p>
                            <p className="sm:text-xs text-[10px] text-back-gray">{t('AdditionalUsers')}</p>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col sm:justify-between px-7">
                        <div className="flex flex-row sm:w-1/2 rounded-xl border border-border-grey pl-2">
                            <i className="ph-bold ph-magnifying-glass p-2 "></i>
                            <input onChange={(e) => searchUser(e.target.value)} type="text" name="search" id="search" aria-label={t('search')} className="text-xs rounded-xl w-full py-2.5 px-2" />
                        </div>
                        <Link to={`user-settings?orgId=${orgId}&role=${role}`} className="text-xs text-back-gray pr-7 pt-3 hover:text-black/60 cursor-pointer">{t('AddUser')}</Link>
                    </div>
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                                    {t('UserName')}
                                </th>
                                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                                    {t('Status')}
                                </th>
                                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user: User) => {
                                return (
                                    <tr key={user?.userId}>
                                        <td className="border-b flex flex-col p-3 font-normal text-xs">
                                            <p>{user?.userName} </p>
                                            <p className="hidden sm:flex">{user?.email}</p>
                                        </td>
                                        <td className="border-b p-3 font-normal text-xs ">
                                            {user?.status === 'ACTIVE' &&
                                                <p className="text-resolved-green">{titleCase(user?.status)}</p>
                                            }
                                            {user?.status === 'INVITATION_PENDING' &&
                                                <p className="text-highlight-blue">{titleCase(user?.status)}</p>
                                            }
                                            {user?.status === 'UNVERIFIED' &&
                                                <p className="text-status-orange">{titleCase(user?.status)}</p>
                                            }
                                            {user?.status === 'SUSPENDED' &&
                                                <p className="text-highlight-red">{titleCase(user?.status)}</p>
                                            }
                                        </td>
                                        <td className="border-b font-normal text-xs">
                                            <Tooltip
                                                title={t('EditUser')}
                                                position="bottom"
                                                arrow={false}
                                                animation="shift"
                                                size="small"
                                                {...({ children: <Link to={`user-settings/${user?.userId}`}><i className="ph-bold ph-pencil-simple-line cursor-pointer hover:text-border-grey mr-4 text-base"></i></Link> } as any)} // Type assertion to bypass TS errors
                                            >
                                            </Tooltip>
                                            <Tooltip
                                                title={t('ResendVerificationLink')}
                                                position="bottom"
                                                arrow={false}
                                                animation="shift"
                                                size="small"
                                                {...({ children: <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-arrow-counter-clockwise mr-4" onClick={(e) => resendInvitation(user?.userId)}></i> } as any)} // Type assertion to bypass TS errors
                                            >
                                            </Tooltip>
                                            <Tooltip
                                                title={t('DeleteUser')}
                                                position="bottom"
                                                arrow={false}
                                                animation="shift"
                                                size="small"
                                                {...({ children: <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-user-circle-minus" onClick={(e) => deleteUser(user?.userId)}></i> } as any)} // Type assertion to bypass TS errors
                                            >
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="flex flex-row gap-2 justify-end mb-2">
                        {page > 1 &&
                            <button aria-label={t('PreviousPage')} onClick={() => setPage(page - 1)} className="p-2 mt-2 rounded-lg"><ArrowLeft size={20} weight="bold" /></button>
                        }
                        <p className=" p-2 mt-2 rounded-lg" aria-label={`On Page ${page}`}>{page}</p>
                        {users.length >= limit &&
                            <button aria-label={t('NextPage')} onClick={() => setPage(page + 1)} className=" p-2 mt-2 rounded-lg"><ArrowRight size={20} weight="bold" /></button>
                        }
                    </div>
                    <p className="text-xs text-back-gray text-center pb-4">{limit * (page - 1) + users?.length} of {adminCount}</p>
                </div>

            </div>
          </div>
  );
}

export default UserManagementSettings;
