import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AdminContainer from './AdminContainer';
import ComplainList from './Complain/ComplainList';
import ComplaintDetails from './Complain/ComplaintDetails';
import MarketParticipantVerification from './Organisation/MarketParticipantVerification';
import RolesList from './Roles/RolesList';
import TrackStatus from './Track-Status/TrackStatus';
import Settings from './Settings/Settings';
import AccountSettings from './Settings/AccountSettings';
import PrivacySettings from './Settings/PrivacySettings';
import UserManagementSettings from './Settings/UserManagementSettings';
import UserSettings from './Settings/UserSettings';
import { ApiController } from '../../apiController';
import Dashboard from '../Dashboard';
import Organizations from './Settings/Organizations';
import OrganisationManegement from './Settings/OrganisationManegement';
import OrganisationSettings from './Settings/OrganisationSettings';
import MergeAccountSettings from './Settings/MergingAccountSettings';
import posthog from 'posthog-js';
import ComplainFilingContainer from './ComplaintFiling/ComplainFilingContainer';

  /**
   * The AdminModule component contains routes for all the admin pages.
   * If the user is not logged in, it redirects to the login page.
   * It also handles the user authentication and fetches the user profile.
   * It also handles the google reCaptcha and identifies the user in posthog.
   * It also identifies the user in posthog.
   * @returns The AdminModule component.
   */
function AdminModule() {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const navSubscription = ApiController.getInstance().UserLoggedIn$.subscribe(
      async (userLoggedIn) => {
        if (userLoggedIn && userLoggedIn.userType === 'SEBI') {
          setIsAuthenticated(true);
          const user = await ApiController.getInstance().authApi.getProfile();
          ApiController.getInstance().updateUserProfile(user.data);
          posthog.identify(user.data.email, {
            phone: user.data.phoneNumber,
            role: user.data.userType,
            city: user.data.city,
            state: user.data.state,
            pincode: user.data.pinCode,
          })
        } else {
          setIsAuthenticated(false);
          navigate("/admin/login");
        }
      }
    );
    return () => {
      navSubscription.unsubscribe()
    }
  }, []);

  return (
    <React.Fragment>
      <div role="heading" aria-level={1} className="flex pb-0 mx-auto px-2 w-full h-full ">
      <div>{isAuthenticated && <AdminContainer />}</div>
        <div className="w-full min-h-full" role="main">
          <Routes>
          <Route
              path="dashboard"
              element={<Dashboard />}
              index={true}
            />
            <Route path="disputes">
              <Route index={true} element={<ComplainList />} />
              <Route
                index={false}
                path="disputeDetails/:complainUUID"
                element={<ComplaintDetails />} />
            </Route>
            <Route
              path="track-status"
              element={<TrackStatus />}
              index={true}
            />
            <Route
              path="dispute-registration"
              element={<ComplainFilingContainer />}
              index={true}
            />
            <Route path="settings">
              <Route index={true} element={<Settings />} />
              <Route
                index={false}
                path="account-settings"
                element={<AccountSettings />} />
              <Route
                index={false}
                path="security"
                element={<PrivacySettings />} />
              <Route
                index={false}
                path="duplicate-account-settings"
                element={<MergeAccountSettings />} />
              <Route
                index={false}
                path="user-management"
                element={<UserManagementSettings />} />
              <Route
                index={false}
                path="user-management/user-settings"
                element={<UserSettings />} />
              <Route
                index={false}
                path="user-management/user-settings/:userId"
                element={<UserSettings />} />
                <Route
                index={false}
                path="organisation-management"
                element={<Organizations />} />
              <Route
                index={false}
                path="organisation-management/:role"
                element={<OrganisationManegement />} />
              <Route
                index={false}
                path="organisation-management/:role/organisation-settings"
                element={<OrganisationSettings />} />
              <Route
                index={false}
                path="organisation-management/:role/organisation-settings/:orgId"
                element={<OrganisationSettings />} />
            </Route>
            <Route
              path="roles"
              element={<RolesList />}
              index={true}
            />
            <Route
              path="*"
              element={<Navigate replace={true} to="dashboard" />}
            />
            <Route
              path="/"
              element={<Navigate replace={false} to={"dashboard"} />}
            />
            <Route
              path="organisations"
              element={<MarketParticipantVerification />}
              index={true}
            />
          </Routes>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AdminModule