import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { ApiController } from "../../../apiController"
import { userFormValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { User } from "../../../swagger/models"
import { CityType, StateType, encryptString } from "../../common-module/helper"
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying and editing the user's account settings.
 *
 * Handles the retrieval of the user's data from the API, displays the data in a form
 * and updates the user's data when the form is submitted.
 */
function AccountSettings() {
  const { t } = useTranslation();
  const [userDetail, setUserDetails] = useState<User>({})
  const [userDetailRequest, setUserDetailRequest] = useState<User>({  })

  const updateProfile = useCombinedStore((state: any) => state.updateProfile)
  const currentURL = window.location.href.split('/')[3]
  const navigate = useNavigate()

  const formOptions = {
    resolver: yupResolver(userFormValidationSchema),
  }

  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  /**
   * 
   * @param e 
   */
  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setUserDetails({
      ...userDetail,
      [name]: value,
    })
    setUserDetailRequest({
      ...userDetailRequest,
      [name]: (name === 'email' || name === 'panNumber' || name === 'pinCode' || name === 'phoneNumber') ? encryptString(value) : value,
    })
  }

  /**
   * 
   */
  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUserDetails(user)
        setUserDetailRequest({
          ...user,
          email: encryptString(user.email), 
          phoneNumber: encryptString(user.phoneNumber), 
          pinCode: encryptString(user.pinCode), 
          panNumber: encryptString(user.panNumber), 
          RoleMaster: {},
          UserParents: [],
          otherMetaData: {},
        })
        reset(user)
      }
    });
  }, [])

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    updateProfile(userDetailRequest)
    return false
  }

  const redirect = () => {
    navigate(`/${currentURL}/settings`)
  }

  return (
    <div className="pt-7 w-full h-fit pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='text-xs sm:block hidden text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('UserSettings')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{t('UserSettings')}</p>
              <p className="sm:text-xs text-[10px] text-back-gray">{t('PersonalInformation')}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="flex flex-col gap-2 px-7 sm:w-1/2">
              <label htmlFor="userName" className="text-xs">{t('Name')}*</label>
              <input {...register('userName')} onChange={onInputChange} value={userDetail?.userName} required aria-label={t('Name')} type="text" name='userName' id='userName' className={`rounded-xl border text-xs p-2 border-solid ${errors.userName?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.userName?.message}</span>

              <label htmlFor="email" className="text-xs">{t('email')}</label>
              <input disabled {...register('email')} onChange={onInputChange} value={userDetail?.email} required aria-label={t('email')} type="email" name='email' id='email' className={`rounded-xl border p-2 text-xs border-solid ${errors.email?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.email?.message}</span>

              <label htmlFor="phoneNumber" className="text-xs">{t('MobileNumber')}*</label>
              <input {...register('phoneNumber')} onChange={onInputChange} value={userDetail?.phoneNumber} required aria-label={t('MobileNumber')} type="text" name='phoneNumber' id='phoneNumber' className={`rounded-xl text-xs border p-2 border-solid ${errors.phoneNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.phoneNumber?.message}</span>

              <label htmlFor="PAN" className="text-xs">{t('PANNumber')}*</label>
              <input {...register('panNumber')} onChange={onInputChange} value={userDetail?.panNumber} required aria-label={t('PANNumber')} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 text-xs border-solid ${errors.panNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.panNumber?.message}</span>

              <label htmlFor="Address" className="text-xs">{t('Address')}*</label>
              <input {...register('address')} onChange={onInputChange} value={userDetail?.address} required aria-label={t('Address')} type="text" name='address' id='address' className={`rounded-xl border p-2 text-xs border-solid ${errors.address?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.address?.message}</span>
              <label htmlFor="State" className="text-xs">{t('State')}*</label>
              <select
                aria-label={t('SelectState')} required name='state' id='state' onChange={onInputChange}
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.state}
              >
                <option>{t('SelectState')}</option>
                {StateType?.map((state: string) => {
                  return <option key={state} value={state}>{state}</option>
                })}
              </select>
              <div className="flex sm:flex-row flex-col sm:justify-between">
                <div className="sm:w-1/2 flex flex-col gap-2 sm:pr-4">
                  <label htmlFor="City" className="text-xs">{t('City')}*</label>
                  <select
                    aria-label={t('SelectCity')} {...register('city')} required name='city' id='city' onChange={onInputChange}
                    className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.city}
                  >
                    <option>{t('SelectCity')}</option>
                    {CityType.filter((city: any) => city.state === userDetail?.state)?.map((city: any) => {
                      return <option key={city} value={city.city}>{city.city}</option>
                    })}
                  </select>
                  <span className="text-xs text-highlight-red">{errors.city?.message}</span>
                </div>
                <div className="sm:w-1/2 flex flex-col gap-2">
                  <label htmlFor="Pincode" className="text-xs">{t('Pincode')}*</label>
                  <input {...register('pinCode')} onChange={onInputChange} value={userDetail?.pinCode} required aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`rounded-lg text-xs border h-full p-2 border-solid ${errors.pinCode?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                  <span className="text-xs text-highlight-red">{errors.pinCode?.message}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default AccountSettings