import { useEffect, useState } from "react"
import { notifyError } from "../../../helpers/helper";
import { useCombinedStore } from "../../../store";
import { ComplainComment, ComplainNature, ThirdLevelCategory } from "../../../swagger/models";
import { dateTimeHelper } from "../../common-module/helper";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "react-tippy";
import DocumentViewerModal from "../../DocumentViewerModal";

/**
 * This component renders the pre-conciliation page for the complaint.
 * 
 * Props:
 * - complaintDetails: The complaint details object.
 * - user: The user object.
 * 
 * It displays the list of nature of complaints and the related comments.
 * It also allows the user to add a comment to the nature of complaint.
 * The component also displays the list of attachments for each nature of complaint.
 * The component also allows the user to add a new attachment to the nature of complaint.
 * The component is used in the MII and ODR roles.
 */
function PreConciliation({ complaintDetails, user }: any) {

  const { t } = useTranslation();
  const addCommentApi = useCombinedStore((state: any) => state.addComment);
  const [collapsedComments, setCollapsedComments] = useState<string[]>([]);
  const [complainComment, setComplainComment] = useState<ComplainComment>({})
  const [complainNatureDetails, setComplainNatureDetails] = useState<ComplainNature>({})
  const [selectedNatureType, setSelectedNatureType] = useState<ComplainNature>({})
  const [natureOfComplaint, setNatureOfComplaint] = useState<any>()
  const [previewLink, setPreviewLink] = useState<string>('')
  const [complainNatureId, setComplainNatureId] = useState<number>()
  const [selectedFile, setSelectedFile] = useState<any>({})

  const uploadNatureFile = useCombinedStore((state: any) => state.uploadNatureFile);
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const subCategoryMaster = useCombinedStore((state: any) => state.subCategoryMaster)
  const updateNatureType = useCombinedStore((state: any) => state.updateNatureType)
  const updatedDisputeNatureType = useCombinedStore((state: any) => state.updatedDisputeNatureType)

  const currentURL = window.location.href.split('/')[3]

  const uploadingFile = (index: number) => {
    setComplainNatureDetails(complaintDetails?.ComplainNatures[index])
  }

  const addComment = () => {
    if (complainComment?.description?.length > 0) {
      addCommentApi(complainComment)
      setComplainComment({})
    }
  }

  const handleCollapse = (index: any) => {
    setCollapsedComments((prevCollapsed) => {
      const isCollapsed = prevCollapsed.includes(index);
      if (isCollapsed) {
        return prevCollapsed.filter((item) => item !== index);
      } else {
        return [...prevCollapsed, index];
      }
    });
  };

  const isCollapsed = (index: any) => {
    return collapsedComments.includes(index);
  };

  const updateComment = (e: any, complainNature: ComplainNature, comment?: ComplainComment) => {
    if (complainComment?.complainNatureId === complainNature?.complainNatureId) {
      setComplainComment({
        ...complainComment,
        description: e?.target?.value
      })
    }
  }

  const handleFileUpload = (e: any) => {
    if (e.files[0].size > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return true;
    }
    uploadNatureFile(e.files[0], complainNatureDetails?.complainNatureId)
  }

  const setComment = (complainNature: ComplainNature, comment?: ComplainComment) => {
    setComplainComment({
      ...complainComment,
      parentId: comment?.complainCommentId,
      complainNatureId: complainNature?.complainNatureId,
      complainId: complaintDetails?.complainId,
      userId: user?.userId,
      userType: user?.userType
    })

  }

  const downloadFile = async (key: any, complainNatureId: number) => {
    let res = await downloadFileOfComplaint(key, complaintDetails?.complainId, complainNatureId, null, null)
    if (!res.error) window.open(res.file)
  }

  const generateLink = async (key: string, complainNatureId: number, file: any) => {
    try {
      let res = await downloadFileOfComplaint(key, complaintDetails?.complainId, complainNatureId, undefined, undefined)
      setPreviewLink(res.file)
      setSelectedFile(file)
      setComplainNatureId(complainNatureId)
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  }

  /**
 * 
 * @param event 
 * @param nature 
 */
  const updateNatureOfComplaint = (event: any) => {
    const { name, value } = event.target
    setSelectedNatureType({
      ...selectedNatureType,
      [name]: value,
    })
  }

  useEffect(() => {
    if (subCategoryMaster && subCategoryMaster.subCategoryId) {
      if (subCategoryMaster.ThirdLevelCategories?.length > 0) {
        const thirdCategory = subCategoryMaster.ThirdLevelCategories.find((thirdCategory: ThirdLevelCategory) => thirdCategory.thirdLevelCategoryId === complaintDetails?.subSubCategoryId)
        setNatureOfComplaint(thirdCategory?.natureOfComplaint);
      } else {
        setNatureOfComplaint(subCategoryMaster?.natureOfComplaint);
      }
    }
  }, [subCategoryMaster])

  const saveNatureType = () => {
    updateNatureType(selectedNatureType)
  }

  useEffect(() => {
    if (updatedDisputeNatureType && updatedDisputeNatureType.complainNatureId) {
      setSelectedNatureType({})
    }
  }, [updatedDisputeNatureType])

  useEffect(() => {
    document.body.style.overflow = previewLink && previewLink !== "" ? "hidden" : "unset";
  }, [previewLink]);

  return (
    <div className={`flex sm:flex-row flex-col justify-between`}>
      <div className="sm:w-2/3 w-full">
        {complaintDetails?.ComplainNatures?.map((complainNature: ComplainNature, index: number) => {
          return (
            <div className="flex flex-row justify-between">
              <div key={complainNature?.complainNatureId} className="w-full flex flex-col gap-4">
                <div className="flex sm:flex-row flex-col w-full pr-10 pt-10">
                  <div className="flex flex-col sm:w-1/3">
                    <p className="text-xs text-label-grey">{index === 0 ? `${t('DisputeDetails')}:` : ''}</p>
                    <p className="text-xs text-back-gray">{index === 0 ? `${complaintDetails?.ComplainNatures?.length} ${complaintDetails?.ComplainNatures?.length > 1 ? t('Disputes') : t('Dispute')} ${t('Filed')}` : ''}</p>
                  </div>
                  <div className="flex flex-col sm:w-2/3 gap-3">
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complainNature?.natureType} / {complainNature?.subNatureType}</p>
                      {currentURL === 'mii' && complainNature?.natureType === 'Others' && <button onClick={(e: any) => setSelectedNatureType({
                        ...selectedNatureType,
                        complainNatureId: complainNature?.complainNatureId,
                        complainId: complaintDetails?.complainId,
                      })} className="text-xs text-label-grey pl-3 pr-3">{t('Edit')}</button>}
                      {currentURL === 'mii' && selectedNatureType?.complainNatureId === complainNature?.complainNatureId &&
                        <button onClick={saveNatureType} className="text-xs text-label-blue pl-3 pr-3">{t('Save')}</button>
                      }
                    </div>
                    {currentURL === 'mii' && selectedNatureType?.complainNatureId === complainNature?.complainNatureId &&
                      <select
                        aria-label={t('SelectNature')} required name='natureType' id='natureType' onChange={(e) => updateNatureOfComplaint(e)}
                        className="rounded-lg border p-3 border-border-grey border-solid text-xs"
                      >
                        <option>{t('SelectNature')}</option>
                        {natureOfComplaint?.map((natureType: any) => {
                          return <option key={natureType?.natureType} value={natureType?.natureType}>{natureType?.natureType}</option>
                        })}
                      </select>}

                    {currentURL === 'mii' && complainNature?.otherMetaData?.miiNatureType &&
                      <p className="text-xs py-3 text-label-blue pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complainNature?.otherMetaData?.miiNatureType}</p>
                    }
                    <textarea className="border border-border-grey disabled:bg-disable-grey py-3 px-5 text-xs text-label-grey rounded-xl"
                      rows={7} disabled={true}
                      value={complainNature?.description}></textarea>
                    {user?.userType !== 'ODR' && complaintDetails?.status === 'AWAITING_MII_REVIEW' &&
                      <p onClick={(e) => setComment(complainNature)} className="text-xs text-label-grey cursor-pointer hover:text-back-gray hover:underline">+ {t('AddComment')}</p>
                    }
                    {complainNature?.ComplainComments?.map((comment: ComplainComment, parentIndex: any) => {
                      return (<>
                        {!comment?.parentId &&
                          <div className="flex flex-col pl-3 gap-3 border-l border-border-grey">
                            <div className="flex flex-row gap-2">
                              <p className="text-xs text-highlight-red font-bold">{comment?.User?.userName}, {comment?.User?.Organisation?.organisationName || 'Investor'}</p>
                              <p className="text-xs text-back-gray">{dateTimeHelper(comment?.createdAt)}</p>
                            </div>
                            <p className="text-xs text-highlight-red px-4 py-2 rounded-lg border border-highlight-red">{comment?.description}</p>
                            {/* Use this div to multithread the comments */}
                            {comment?.NestedComments?.map((nestedComment: ComplainComment, nestedIndex: any) => {
                              return (
                                <>
                                  {
                                    !isCollapsed(parentIndex) && (
                                      <div key={nestedIndex}
                                        className={`flex flex-col gap-3 pl-3 border-l border-border-grey 
                                        }`}>
                                        <div className="flex flex-row gap-2">
                                          <p className="text-xs text-highlight-red font-bold">{nestedComment?.User?.userName}, {nestedComment?.User?.Organisation?.organisationName || 'Investor'}</p>
                                          <p className="text-xs text-back-gray">{dateTimeHelper(nestedComment?.createdAt)}</p>
                                        </div>

                                        <p className="text-xs text-highlight-red px-4 py-2 rounded-lg border border-highlight-red">{nestedComment?.description}</p>
                                      </div>
                                    )
                                  }

                                </>
                              )
                            })}
                            {comment?.NestedComments?.length > 0 && (
                              <>
                                <p
                                  className={`text-xs text-back-gray cursor-pointer hover:text-back-gray hover:underline ${isCollapsed(index) ? '' : 'hidden'
                                    }`}
                                  onClick={() => handleCollapse(parentIndex)}
                                >
                                  {comment?.NestedComments?.length} {t('replies')}
                                </p>
                                <p
                                  className={`text-xs text-back-gray cursor-pointer hover:text-back-gray hover:underline ${isCollapsed(index) ? 'hidden' : ''
                                    }`}
                                  onClick={() => handleCollapse(parentIndex)}
                                >
                                  {t('Collapse')}
                                </p>
                              </>
                            )}

                            {complainComment?.parentId === comment?.complainCommentId && complaintDetails?.status === 'AWAITING_MII_REVIEW' &&
                              <>
                                <p className="text-xs text-label-grey">Reply as <span className="font-bold">{user?.userName}, {user?.userType || 'Investor'}</span></p>
                                <div className="flex flex-row bg-white border border-border-grey rounded-xl items-end">
                                  <textarea value={complainComment?.description} id="description" onChange={(e) => updateComment(e, complainNature, comment)} className="w-full rounded-xl p-2 text-xs" rows={3}></textarea>
                                  <i className="ph-bold ph-paper-plane-tilt p-2 text-back-gray" onClick={() => addComment()}></i>
                                </div>
                              </>
                            }
                            {user?.userType !== 'ODR' && complaintDetails?.status === 'AWAITING_MII_REVIEW' &&
                              <p onClick={(e) => setComment(complainNature, comment)} className="text-xs text-back-gray cursor-pointer hover:text-back-gray hover:underline">
                                + {t('Reply')}</p>
                            }
                          </div>
                        }
                      </>
                      )
                    })}
                    {complainComment?.complainNatureId === complainNature?.complainNatureId && !complainComment?.parentId && complaintDetails?.status === 'AWAITING_MII_REVIEW' &&
                      <>
                        <p className="text-xs text-label-grey">Reply as <span className="font-bold">{user?.userName}, {user?.userType}</span></p>
                        <div className="flex flex-row bg-white border border-border-grey rounded-xl items-end">
                          <textarea value={complainComment?.description} id="description" onChange={(e) => updateComment(e, complainNature)} className="w-full rounded-xl p-2 text-xs" rows={3}></textarea>
                          <i className="ph-bold ph-paper-plane-tilt p-2 text-back-gray" onClick={() => addComment()}></i>
                        </div>
                      </>
                    }
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full pr-10 pt-5">
                  <p className="text-xs sm:w-1/3 text-label-grey">{index === 0 ? t('Attachments') : ''}:</p>
                  <div className="flex flex-col sm:w-2/3 gap-2">
                    {complainNature?.files?.map((file: any) => {
                      return (
                        // <div className="flex flex-col gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl">
                        //   <p className="cursor-pointer" onClick={(e) => downloadFile(file.file, complainNature?.complainNatureId)}>{file?.fileName}</p>
                        //   <p className="text-back-gray">{t('uploadedby', {
                        //     name: file?.uploadedBy,
                        //     date: dateTimeHelper(file?.uploadedAt)
                        //   })}
                        //   </p>
                        // </div>
                        <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                          <div
                            onClick={(e) => generateLink(file.file, complainNature?.complainNatureId, file)}
                            className="relative flex flex-col cursor-pointer gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl group overflow-hidden"
                          >
                            {/* File Name and Upload Info */}
                            <p>{file?.fileName?.length > 20 ? (file?.fileName).slice(0, 20) + '...' : file?.fileName}</p>
                            <p className="text-back-gray">
                              {t('uploadedby', {
                                name: file?.uploadedBy,
                                date: dateTimeHelper(file?.uploadedAt)
                              })}
                            </p>
                            <div className="absolute gap-1 inset-0 bg-attachment-grey bg-opacity-70 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                              <i className="ph ph-eye text-label-grey text-lg"></i>
                              <p className="text-label-grey text-xs">{t('ViewDocument')}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {complaintDetails?.status === 'AWAITING_MII_REVIEW' && (
                      <label htmlFor='upload' className="flex flex-col gap-1">
                        <p className="text-xs text-label-grey cursor-pointer hover:text-back-gray hover:underline" onClick={(e) => uploadingFile(index)} >+ {t('AddAttachment')}</p>
                        <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                          <p>{t('AcceptedFormat')}</p>
                          <p>{t('Note')}: {t('TotalFileSize')}</p>
                        </div>
                        <input
                          onChange={(e) => handleFileUpload(e.target)}
                          multiple aria-label={t('Choosefile')} type="file" id="upload" accept=".jpeg, .pdf, .png, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" style={{ display: 'none' }} />
                      </label>
                    )}

                  </div>
                </div>
              </div>
            </div>)
        })}
      </div>
      <div className="flex flex-col gap-2 mt-10 rounded-xl bg-light-blue text-highlight-blue text-xs h-fit p-3">
        <p className="font-bold">{t('HowtoAddComments')}</p>
        <p>{t('IntermediariesAndMIIs')}</p>
        <p>{t('EverytimeYouAddComment')}</p>
      </div>
      {previewLink && (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
          <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
            <div className="flex justify-center pt-4 w-full h-full">
              <div
                className="fixed inset-0 transition-opacity"
                onClick={() => setPreviewLink("")}
              >
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <div
                className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <h1 className='font-semibold sm:text-2xl text-lg pt-3 mr-2'>{selectedFile?.fileName}&nbsp;&nbsp;
                      <Tooltip
                        html={
                          <div className=" text-left flex flex-col gap-1">
                            <p className="font-semibold">{t('Download')} {selectedFile?.fileName}</p>
                          </div>
                        }
                        position="top"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({
                          children: <i onClick={() => downloadFile(selectedFile?.file, complainNatureId!)}
                            className="ph ph-download-simple text-label-grey h-fit p-0.5" style={{ borderRadius: '50%' }}></i>
                        } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </h1>
                    <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setPreviewLink("")}></i>
                  </div>
                  <DocumentViewerModal selectedFile={selectedFile} previewLink={previewLink}></DocumentViewerModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PreConciliation