import { useCallback, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { ApiController } from '../../apiController';
import { loginFormValidationSchema } from '../../helpers/validation';
import { useCombinedStore } from '../../store';
import { LoginRequest, User } from '../../swagger/models';
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { notifySuccess, notifyError } from '../../helpers/helper';
import { useTranslation } from 'react-i18next';
import { encryptString } from '../common-module/helper';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Eye, EyeSlash } from 'phosphor-react';
import { filter, take } from 'rxjs';

/**
 * Component for user login
 * 
 * Handles user login, OTP verification and resending of OTP
 * 
 * @returns JSX.Element
 */
function Login() {
  // Get the navigate function from react-router-dom
  const navigate = useNavigate();

  // Get the role from the URL parameters
  const params = useParams();

  // Set the form options for the login form
  const formOptions = {
    resolver: yupResolver(loginFormValidationSchema),
  }

  // Use the useForm hook to get the form state and functions
  const { register, handleSubmit, formState, reset } = useForm(formOptions);

  // Get the errors from the form state
  const { errors } = formState;

  // Use the useTranslation hook to get the translation function
  const { t, i18n } = useTranslation();

  // Set the initial login request state
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: '',
    password: '',
    isRememberme: false,
    captchaToken: '',
    otp: '',
    role: 'investor',
  });

  // Set the initial OTP request state
  const [OTPRequest, setOTPRequest] = useState<LoginRequest>({})

  // Set the send OTP on state
  const [sendOTPOn, setSendOTPOn] = useState<boolean>(false)

  // Set the refresh captcha state
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0)

  // Set the password type state
  const [passwordType, setPasswordType] = useState<string>('password')

  // Get the user role from the URL parameters
  const role: string = params.role || "investor"

  // Set the current user role state
  const [currentUserRole, setCurrentUserRole] = useState<string>('Investor')

  // Use the useCombinedStore hook to get the login function
  const login = useCombinedStore((state: any) => state.login)

  // Use the useCombinedStore hook to get the isLoading state
  const isLoading = useCombinedStore((state: any) => state.isLoading)
  // Function to send OTP
  const sendOTP = async () => {
    try {
      console.log("Send OTP", OTPRequest)
      const otpData = await ApiController.getInstance().openApi.sendOtp(
        OTPRequest
      );
      // If the OTP is enabled, set the send OTP on state to true
      if (otpData.data.isEnabled) {
        setSendOTPOn(otpData.data.isEnabled)
      } else {
        // If the OTP is not enabled, log the user in
        login(OTPRequest)
      }
    } catch (error: any) {
      // If there is an error, notify the user
      notifyError(error?.response?.data?.message);
    }
  }

  // Function to resend OTP
  const resendOTP = async () => {
    // Call the resend OTP API
    const otpData = await ApiController.getInstance().openApi.resendOtp({
      role: currentUserRole,
      email: loginRequest?.email,
      captchaToken: loginRequest?.captchaToken,
    });
    // Increment the refresh captcha state
    setRefreshCaptcha(refreshCaptcha + 1)
    // If the OTP is enabled, set the send OTP on state to true
    if (otpData.data.isEnabled) {
      setSendOTPOn(otpData.data.isEnabled)
    }
  }

  // Function to handle input changes
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // Update the login request state
    setLoginRequest((prev: LoginRequest) => ({
      ...prev,
      [name]: value,
      role: currentUserRole
    }));

    // Update the OTP request state
    setOTPRequest((prev: LoginRequest) => ({
      ...prev,
      [name]: name === 'password' ? encryptString(value) : value,
      role: currentUserRole
    }));
  }

  // Function to handle the form submission
  async function onSubmit(e: any) {
    // Increment the refresh captcha state
    setRefreshCaptcha((prev: number) => prev + 1);
    // If the user has checked the remember me checkbox, save the email in local storage
    if (loginRequest.isRememberme) {
      localStorage.setItem('email', loginRequest.email)
    }
    // If the OTP is enabled, log the user in
    if (sendOTPOn) {
      login(OTPRequest)
      setRefreshCaptcha((prev: number) => prev + 1);
    } else {
      // If the OTP is not enabled, send the OTP
      sendOTP()
      setRefreshCaptcha((prev: number) => prev + 1);
    }
    return false
  }

  // Use the useEffect hook to set the current user role state
  useEffect(() => {
    const navSubscription = ApiController.getInstance().UserLoggedIn$.pipe(
      filter(user => !!user),
      take(1),
    ).subscribe((userLoggedIn: User | null) => {
      if (userLoggedIn) {
        switch (userLoggedIn.userType) {
          case "Investor":
            navigate(`/investor/disputes`)
            break;
          case "ODR":
            navigate(`/odr/disputes`)
            break;
          case "SEBI":
            navigate(`/admin/disputes`)
            break;
          case "MII":
            navigate(`/mii/disputes`)
            break;
          case "Intermediary":
            navigate(`/intermediary/profiles`)
            break;
          default:
            navigate(`/login`)
            break;
        }
      }
    })
    return () => {
      navSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    setLoginRequest({})
    setOTPRequest({})
    reset()
    console.log(role)
    switch (role) {
      case "investor":
        setCurrentUserRole('Investor')
        break;
      case "odr":
        setCurrentUserRole('ODR')
        break;
      case "admin":
        setCurrentUserRole('SEBI')
        break;
      case "mii":
        setCurrentUserRole("MII")
        break;
      default:
        setCurrentUserRole('Investor')
        break;
    }
  }, [role])

  // Use the useEffect hook to set the login request and OTP request states
  useEffect(() => {
    setLoginRequest({
      ...loginRequest,
      role: currentUserRole
    })
    setOTPRequest({
      ...OTPRequest,
      role: currentUserRole
    })
  }, [currentUserRole])

  // Use the useCallback hook to create a function to verify the captcha
  const onVerify = useCallback((token: string) => {
    setLoginRequest({
      ...loginRequest,
      captchaToken: token
    })
    setOTPRequest({
      ...OTPRequest,
      captchaToken: token
    })
    console.log("OTPRequest", OTPRequest.email, loginRequest.email)
  }, [refreshCaptcha]);

  // Function to toggle the password type
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  // Return the JSX
  return (

    <div className="flex flex-row justify-between pt-7 px-10 lg:pl-24 pb-7 w-full h-screen">
      <div className="flex flex-col h-full w-full lg:w-1/3 sm:pt-20 pt-10 justify-between">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
        <div>
          <div className='flex flex-row justify-between items-start'>
            <NavLink to="/login">
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
            </NavLink>
            <div className='hidden lg:block'>
              <div className='grid grid-cols-2 gap-2'>
                {role !== 'mii' && <Link to="/mii/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('MII')} {t('login')}</Link>}
                {role !== 'investor' && <Link to="/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('Investor')} {t('login')}</Link>}
                {role !== 'intermediary' && <Link to="/intermediary/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('MarketParticipant')} {t('login')}</Link>}
                {role !== 'odr' && <Link to="/odr/login" className="text-xs pt-2 w-20 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap pr-7">{t('ODRInstitution')} {t('login')}</Link>}
              </div>
            </div>

          </div>
          {currentUserRole !== "Intermediary" && (
            <div className="pt-24 pb-6">
              <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                <p className="font-bold text-highlight-blue">{t('DisputeDataDashboard')}</p>
                <p className="text-highlight-blue">{t('Click')} <NavLink to="/dashboard" className='hover:underline' style={{ color: '#306AFF' }}>{t('here')} </NavLink>{t('disputeDataDashboardNote')}
                </p>
              </div>
            </div>
          )}
          <h1 className={`${currentUserRole === "Intermediary" ? 'pt-32 text-3xl text-highlight-blue font-bold' : 'text-3xl text-highlight-blue font-bold'} `}>{currentUserRole === 'ODR' ? t("ODRInstitution") : t(currentUserRole)} {t('login')}</h1>
          {currentUserRole === "Investor" && (<p className="text-xs pt-2">{t('noaccount')} <Link to="/register" style={{ color: '#306AFF' }}>{t('createAccount')}</Link></p>)}

          <form className="flex flex-col pt-12 gap-2" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email" className="text-xs">{t('email')}</label>
            <input required aria-label={t('email')}
              type="text"
              id='email'
              {...register('email')}
              onChange={onInputChange}
              value={loginRequest?.email}
              autoComplete="off" // Try "off", "new-password", or "nope"
              className={`rounded-xl border p-2 border-solid ${errors.email?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
            <span className="text-xs text-highlight-red">{errors.email?.message}</span>
            <div className='flex flex-row justify-between'>
              <label htmlFor="password" className="text-xs pt-4">{t('password')}</label>
              <a href={`/forgot-password?role=${currentUserRole}`} aria-label='Forgot Password' className='text-xs pt-4' style={{ color: '#306AFF' }}>{t('forgotPassword')}?</a>
            </div>
            <div className='flex flex-row justify-between items-center border rounded-lg'>
              <input required type={passwordType} id='password'
                {...register('password')}
                onChange={onInputChange}
                value={loginRequest?.password}
                autoComplete="new-password" // Try "off", "new-password", or "nope"
                aria-label={t('password')} className={`rounded-xl p-2 w-full mr-1 ${errors.password?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <button onClick={togglePassword} type='button' className='px-3'>
                {passwordType === 'password' ? <Eye size={20} weight="thin" /> : <EyeSlash size={20} weight="thin" />}
              </button>
            </div>
            <span className="text-xs text-highlight-red">{errors.password?.message}</span>
            {sendOTPOn &&
              <>
                <label htmlFor="password" className="text-xs">{t('OTP')}</label>
                <input required type={passwordType} id='OTP'
                  {...register('otp')}
                  onChange={onInputChange}
                  aria-label={t('OTP')}
                  value={loginRequest?.otp}
                  className={`rounded-lg border p-2 border-solid ${errors.otp?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.otp?.message}</span>
              </>
            }
            <button aria-label='Login' type='submit' className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
              {t('login')}
            </button>
            {sendOTPOn &&
              <>
                <button aria-label='ResendOTP' type="button" onClick={(e) => { resendOTP(); setRefreshCaptcha(refreshCaptcha + 1) }} className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
                  {t('resendOTP')}
                </button>
              </>
            }
            <div className="flex flex-row gap-2 pt-3  w-full items-start">
              <input type="checkbox" checked={loginRequest?.isRememberme} onChange={() => setLoginRequest({
                ...loginRequest,
                isRememberme: !loginRequest?.isRememberme
              })} />
              <p className="text-xs text-label-grey">{t('keepMeLoggedIn')}</p>
            </div>
          </form>
        </div>
        <div className='flex flex-col justify-between'>
          <p className='text-xs py-10 text-label-grey'>
            {t('leaveUsFeedback')}
            <a href="https://forms.gle/P4yS5cGYoxV6GnsD9" target='_blank' className='hover:underline' style={{ color: '#306AFF' }}> {t('here')}. <br></br>
            </a>
            {t('IncaseofanyIssues')}
            <a href="https://help.smartodr.in/" target='_blank' className='hover:underline' style={{ color: '#306AFF' }}> {t('helpCenter')}</a>
          </p>
        </div>
        <div className='flex flex-row justify-between'>
          <div className='text-xs py-8 text-label-grey flex flex-row flex-wrap gap-2'>
            <p>{t('SmartODROfferedIn')}: </p>
            <p onClick={() => i18n.changeLanguage('en')} className={`${i18n.language==='en' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>English</p>
            <p onClick={() => i18n.changeLanguage('hi')} className={`${i18n.language==='hi' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Hindi</p>
            <p onClick={() => i18n.changeLanguage('gu')} className={`${i18n.language==='gu' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Gujarati</p>
            <p onClick={() => i18n.changeLanguage('te')} className={`${i18n.language==='te' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Telugu</p>
            <p onClick={() => i18n.changeLanguage('ta')} className={`${i18n.language==='ta' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Tamil</p>
            <p onClick={() => i18n.changeLanguage('ma')} className={`${i18n.language==='ma' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Marathi</p>
            <p onClick={() => i18n.changeLanguage('be')} className={`${i18n.language==='be' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Bengali</p>
            <p onClick={() => i18n.changeLanguage('kn')} className={`${i18n.language==='kn' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Kannada</p>
            <p onClick={() => i18n.changeLanguage('ml')} className={`${i18n.language==='ml' ? 'text-link-blue cursor-pointer' : 'hover:text-link-blue cursor-pointer'}`}>Malyalam</p>
          </div>
        </div>

        <div className='grid grid-cols-2 lg:hidden pb-14'>
          {role !== 'odr' && <Link to="/odr/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('ODRInstitution')} {t('login')}</Link>}
          {role !== 'mii' && <Link to="/mii/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MII')} {t('login')}</Link>}
          {role !== 'intermediary' && <Link to="/intermediary/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MarketParticipant')} {t('login')}</Link>}
          {role !== 'investor' && <Link to="/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('Investor')} {t('login')}</Link>}
        </div>
      </div>
      <div className='hidden lg:flex w-1/2 pl-8 justify-end'>
        <div className='hidden lg:block fixed h-full w-fit mb-8 pl-8 py-8 top-0 right-0 rounded-2xl'>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
        </div>
      </div>
    </div>
  )
}

export default Login