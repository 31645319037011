import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { organisationFormValidationSchema } from '../../../helpers/validation'
import { useCombinedStore } from "../../../store"
import { Organisation, OrganisationParent, SubCategoryMaster } from "../../../swagger/models"
import { CityType, StateType } from '../../common-module/helper'
import { useTranslation } from 'react-i18next';

/**
 * OrganisationSettings is a React component that manages the settings of an organisation.
 * It handles the form submission for adding or updating organisation details, manages 
 * state for organisation details and selected Market Infrastructure Institutions (MIIs),
 * and provides options for selecting organisation parents and subcategories. 
 * 
 * The component utilizes react-hook-form for form handling and validation, and 
 * interacts with a global store for accessing and updating organisation details.
 * 
 * The component also includes navigation logic and handles side effects using 
 * React's useEffect hook. It supports both adding new organisations and editing existing 
 * ones, with specific logic for intermediary roles.
 */
function OrganisationSettings() {
  const { t } = useTranslation();
  const [orgDetail, setOrgDetails] = useState<Organisation>({
    country: 'India',
    OrganisationParents: []
  })
  const [selectedMIIs, setSelectedMIIs] = useState<Organisation[]>([])

  const params = useParams()
  const orgId: string = params.orgId!
  const role: string = params.role! || window.location.href.split('/')[3]

  const orgProfile = useCombinedStore((state: any) => state.orgDetail)
  const addOrgProfile = useCombinedStore((state: any) => state.addOrgProfile)
  const newOrgDetails = useCombinedStore((state: any) => state.newOrgDetails)
  const updateOrgProfile = useCombinedStore((state: any) => state.updateOrgProfile)
  const getOrganisation = useCombinedStore((state: any) => state.getOrganisation)
  const subCategoryMasters = useCombinedStore((state: any) => state.subCategoryMasters)
  const listSubCategoryMasters = useCombinedStore((state: any) => state.listSubCategoryMasters)
  const listOrganisations = useCombinedStore((state: any) => state.listOrganisations)
  const organisations: Organisation[] = useCombinedStore((state: any) => state.organisations);
  const currentURL = window.location.href.split('/')[3]
  const addURL = window.location.href.split('/')[6]
  const navigate = useNavigate()

  const formOptions = {
    resolver: yupResolver(organisationFormValidationSchema),
  }

  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  useEffect(() => {
    if (orgId) {
      getOrganisation(orgId);
    } else {
      setOrgDetails({
        country: 'India',
        OrganisationParents: []
      })
    }
    setOrgDetails({
      ...orgDetail,
      organisationType: role?.toUpperCase(),
    })
  }, [orgId]);

  useEffect(() => {
    if (newOrgDetails.orgId) {
      setOrgDetails({
        country: 'India',
        OrganisationParents: []
      })
      redirect()
    }
  }, [newOrgDetails])

  /**
   * 
   * @param e 
   */
  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setOrgDetails({
      ...orgDetail,
      country: 'India',
      [name]: value,
      organisationType: role?.toUpperCase(),
    })
  }

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    // e.preventDefault()
    if (orgDetail.orgId) {
      updateOrgProfile(orgDetail)
    } else {
      addOrgProfile(orgDetail)
    }
    return false
  }

  useEffect(() => {
    if (orgProfile && orgId) {
      setOrgDetails(orgProfile)
      setSelectedMIIs(orgProfile?.OrganisationParents?.map((i: OrganisationParent) => ({
        orgId: i.parentId,
      })))
      reset(orgProfile)
    }
  }, [orgProfile])

  const redirect = () => {
    navigate(`/${currentURL}/settings`)
  }

  const selectOrgParent = (e: any, orgId: number | null, subCategoryId: number, currentOrgId: number | string) => {
    const orgParents = orgDetail.OrganisationParents || []
    if (e.checked) {
      // Add the Parent MII and selected category to the array list
      orgParents.push({
        subCategoryId: subCategoryId,
        parentId: orgId,
        orgId: currentOrgId,
      })
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: orgParents
      })
    } else {
      // Remove the Parent MII and selected category from Array list
      const index = orgParents.findIndex((p: OrganisationParent) => p.orgId === orgId)
      orgParents.splice(index, 1)
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: orgParents
      })
    }
  }

  const selectMII = (e: any, orgId: number | null, currentOrgId: number | string) => {
    const orgParents = selectedMIIs || []
    let selectedOrgParents = orgDetail.OrganisationParents || []
    if (e.checked) {
      // Add the Parent MII to the array list
      orgParents.push({
        orgId: orgId,
      })
      setSelectedMIIs(orgParents)
      selectedOrgParents.push({
        parentId: orgId,
        orgId: currentOrgId,
      })
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: selectedOrgParents
      })
    } else {
      const index = orgParents.findIndex((p: Organisation) => p.orgId === orgId)
      orgParents.splice(index, 1)

      selectedOrgParents = selectedOrgParents.filter((org: OrganisationParent) => org.orgId !== orgId);
      selectedOrgParents.splice(index, 1)
      // Remove the Parent MII from Array list
      setSelectedMIIs(orgParents)
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: selectedOrgParents
      })
    }
  }

  useEffect(() => {
    listSubCategoryMasters()
    listOrganisations({
      orgType: 'MII',
      limit: 10,
      offset: 0,
    });
  }, [])

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full flex-initial  px-8 py-7 flex-col gap-2  rounded-2xl">
        <p className='text-xs sm:block hidden text-back-gray'>
          <NavLink to={`/${currentURL}/settings`}> {t('Settings')} </NavLink> &gt;
          <NavLink to={`/${currentURL}/settings/organisation-management/${addURL}`}>{t("AccountSettings")}</NavLink>
          {addURL.length > 3 && '> Add ' + addURL}</p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <NavLink to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t("Back")}</NavLink>
          {orgProfile && <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{orgProfile?.organisationName} | {orgProfile?.organisationType}</p>
              <p className="text-xs text-back-gray">{orgProfile?.email}</p>
            </div>
          </div>}
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="flex flex-col gap-2 pl-7 sm:w-1/2 px-7 text-xs">
              <label htmlFor="organisationName" className="text-xs">{t('OrganisationName')}</label>
              <input disabled={orgDetail?.orgId} {...register('organisationName')} onChange={onInputChange} value={orgDetail?.organisationName} required aria-label={t('OrganisationName')} type="text" name='organisationName' id='organisationName' className={`rounded-xl border p-2 border-solid ${errors.organisationName?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.organisationName?.message}</span>

              <label htmlFor="primaryContactName" className="text-xs">{t('PrimaryContact')}</label>
              <input {...register('primaryContactName')} onChange={onInputChange} value={orgDetail?.primaryContactName} required aria-label={t('PrimaryContact')} type="text" name='primaryContactName' id='primaryContactName' className={`rounded-xl border p-2 border-solid ${errors.primaryContactName?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryContactName?.message}</span>

              <label htmlFor="primaryEmail" className="text-xs">{t('PrimaryEmailID')}</label>
              <input disabled={orgDetail?.orgId} {...register('primaryEmail')} onChange={onInputChange} value={orgDetail?.primaryEmail} required aria-label={t('PrimaryEmailID')} type="email" name='primaryEmail' id='primaryEmail' className={`rounded-xl border p-2 border-solid ${errors.primaryEmail?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryEmail?.message}</span>

              <label htmlFor="primaryContact" className="text-xs">{t('PrimaryPhoneNumber')}</label>
              <input {...register('primaryContact')} onChange={onInputChange} value={orgDetail?.primaryContact} required aria-label={t('PrimaryPhoneNumber')} type="text" name='primaryContact' id='primaryContact' className={`rounded-xl border p-2 border-solid ${errors.primaryContact?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryContact?.message}</span>

              <label htmlFor="PAN" className="text-xs">{t("PANNumber")}</label>
              <input {...register('panNumber')} onChange={onInputChange} value={orgDetail?.panNumber} aria-label={t("PANNumber")} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 border-solid ${errors.panNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.panNumber?.message}</span>
              {role === 'intermediary' &&
                <>
                  <label htmlFor="uniqueSEBIId" className="text-xs">{t("UniqueSEBIId")}</label>
                  <input {...register('uniqueSEBIId')} onChange={onInputChange} value={orgDetail?.uniqueSEBIId} aria-label={t("UniqueSEBIId")} type="text" name='uniqueSEBIId' id='uniqueSEBIId' className={`rounded-xl border p-2 border-solid ${errors.uniqueSEBIId?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                  <span className="text-xs text-highlight-red">{errors.uniqueSEBIId?.message}</span>
                </>
              }
              <label htmlFor="GST" className="text-xs">{t('GSTNumber')}</label>
              <input {...register('gstNumber')} onChange={onInputChange} value={orgDetail?.gstNumber} aria-label={t('GSTNumber')} type="text" name='gstNumber' id='gstNumber' className={`rounded-xl border p-2 border-solid ${errors.gstNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.gstNumber?.message}</span>
              {role === 'intermediary' && <>
                <label htmlFor="ISINNumber" className="text-xs">{t('ISINNumber')}</label>
                <input {...register('ISINNumber')} onChange={onInputChange} value={orgDetail?.ISINNumber} aria-label={t('ISINNumber')} type="text" name='ISINNumber' id='ISINNumber' className={`rounded-xl border p-2 border-solid ${errors.ISINNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.ISINNumber?.message}</span>

                <label htmlFor="incorporationNumber" className="text-xs">{t('InCorporationNumber')}</label>
                <input {...register('incorporationNumber')} onChange={onInputChange} value={orgDetail?.incorporationNumber} aria-label={t('InCorporationNumber')} type="text" name='incorporationNumber' id='incorporationNumber' className={`rounded-xl border p-2 border-solid ${errors.incorporationNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.incorporationNumber?.message}</span>
              </>
              }
              <label htmlFor="registeredAddress" className="text-xs">{t('Address')}</label>
              <input {...register('registeredAddress')} onChange={onInputChange} value={orgDetail?.registeredAddress} required aria-label={t('Address')} type="text" name='registeredAddress' id='registeredAddress' className={`rounded-xl border p-2 border-solid ${errors.registeredAddress?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.registeredAddress?.message}</span>
              <label htmlFor="State" className="text-xs">{t('State')}</label>
              <select
                aria-label={t('SelectState')} required name='state' id='state' onChange={onInputChange}
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.state}
              >
                <option>{t('SelectState')}</option>
                {StateType?.map((state: string) => {
                  return <option key={state} value={state}>{state}</option>
                })}
              </select>
              <div className="flex flex-row justify-between">
                <div className="w-1/2 flex flex-col gap-2 pr-4">
                  <label htmlFor="City" className="text-xs">{t('City')}</label>
                  <select
                    aria-label={t('SelectCity')} {...register('city')} required name='city' id='city' onChange={onInputChange}
                    className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.city}
                  >
                    <option>{t('Select City')}</option>
                    {CityType.filter((city: any) => city.state === orgDetail?.state)?.map((city: any, index: number) => {
                      return <option key={`${city.city}-${index}`} value={city.city}>{city.city}</option>
                    })}
                  </select>
                  <span className="text-xs text-highlight-red">{errors.city?.message}</span>

                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <label htmlFor="Pincode" className="text-xs">{t('Pincode')}</label>
                  <input {...register('pinCode')} onChange={onInputChange} value={orgDetail?.pinCode} required aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`rounded-lg border p-2 h-full border-solid ${errors.pinCode?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                  <span className="text-xs text-highlight-red">{errors.pinCode?.message}</span>
                </div>
              </div>
              {role === 'intermediary' && currentURL === 'mii' &&
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-2 pr-4">
                    <p className="text-xs">{t('SelectExchangeDepository')} <span className="text-xs text-highlight-red">*</span></p>
                    {/* Show list of all the MII */}
                    {organisations?.map((organisation: Organisation, index: number) => {
                      return <div key={organisation?.orgId} className="flex flex-col gap-2">
                        <div className='flex gap-2 items-center'>
                          <input type="checkbox"
                            name={organisation?.orgId}
                            checked={selectedMIIs?.some(i => i.orgId === organisation.orgId)}
                            onChange={(e) => selectMII(e.target, organisation?.orgId, orgId)} />
                          <label htmlFor={organisation?.organisationName} className="text-xs">{organisation?.organisationName}</label>
                        </div>
                        {/* Show list of all the categories if MII is selected */}
                        {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === organisation.orgId) &&
                          <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                            <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                            {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId === 1 || category.subCategoryId === 4 || category.subCategoryId === 6)?.map((category: SubCategoryMaster, index: number) => {
                              return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                                <input type="checkbox"
                                  name={category?.subCategoryId}
                                  checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === organisation.orgId && op.subCategoryId === category?.subCategoryId)}
                                  onChange={(e) => selectOrgParent(e.target, organisation?.orgId, category?.subCategoryId, orgId)} />
                                <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                              </div>
                            })}
                          </div>
                        }
                      </div>
                    })}
                    <div className="flex flex-col gap-2">
                      <div className='flex gap-2 items-center'>
                        <input type="checkbox"
                          name='N/A'
                          checked={selectedMIIs?.some(i => i.orgId === null)}
                          onChange={(e) => selectMII(e.target, null, orgId)} />
                        <label htmlFor='N/A' className="text-xs">N/A (Not Applicable)</label>
                      </div>
                      {/* Show list of all the categories if MII is selected */}
                      {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === null) &&
                        <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                          <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                          {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId !== 1 && category.subCategoryId !== 4 && category.subCategoryId !== 6)?.map((category: SubCategoryMaster, index: number) => {
                            return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                              <input type="checkbox"
                                name={category?.subCategoryId}
                                checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === null && op.subCategoryId === category?.subCategoryId)}
                                onChange={(e) => selectOrgParent(e.target, null, category?.subCategoryId, orgId)} />
                              <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                            </div>
                          })}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {orgProfile && orgId ? t('Edit') : t('Add')} {t(role)}
              </button>
              <button onClick={redirect} aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
                {t('cancel')}
              </button>

            </div>
          </form>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default OrganisationSettings