import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { scoresValidation } from "../../../helpers/validation"
import InfoTooltip from "../../../helpers/Tooltip"
import { useTranslation } from 'react-i18next';
import { useCombinedStore } from "../../../store"
import { notifyError, notifySuccess } from "../../../helpers/helper"

interface ScoresFilingModalProps {
  setScoredModal?: any
  scoresModal?: boolean,
  setIsRegisteredInScores: any
}

function ScoresFilingModal({ scoresModal, setScoredModal, setIsRegisteredInScores }: ScoresFilingModalProps) {

  const navigate = useNavigate()
  const { t } = useTranslation();
  const fetchScoresDispute = useCombinedStore((state: any) => state.fetchScoresDispute);

  const [scoreDetails, setScoreDetails] = useState<any>({
    primaryCaseRefId: "",
  })
  const [authLetterFile, setAuthLetterFile] = useState<File>()

  const [activeDot, setActiveDot] = useState(1)

  const formOptions = {
    resolver: yupResolver(scoresValidation),
  }

  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState

  const handleScoreDetails = (e: any) => {
    const { name, value } = e.target;
    setScoreDetails({
      ...scoreDetails,
      [name]: value,
    })
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]; // Get the first file from the list
    const formData = new FormData();
    formData.append('file', file);
    if (file.size > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    setAuthLetterFile(file)
    notifySuccess("File uploaded successfully")
  }

  const cancelForm = (e: any) => {
    setScoredModal(!scoresModal)
    navigate('disputes')
  }

  const onSubmit = (e: any) => {
    if (authLetterFile) {
      setIsRegisteredInScores(scoreDetails, authLetterFile);
    } else {
      setIsRegisteredInScores(scoreDetails);
    }

    // Regular expression to match the given pattern
    const regex = /^[A-Z]{4,5}\/[A-Z0-9]{4}\/[A-Z]{4}\/[0-9]{6}\/[0-9]{1,2}$/;
    if (scoreDetails.primaryCaseRefId && scoreDetails.primaryCaseRefId.trim().startsWith("SEBIE")) {
      // Check if the string matches the regex pattern
      if (!regex.test(scoreDetails.primaryCaseRefId.trim())) {
        notifyError('Please provide correct scores complaint Id.')
      } else {
        fetchScoresDispute(scoreDetails.primaryCaseRefId.trim())
      }
    } else {
      setScoredModal(false)
    }
  }

  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/3">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center px-8">
              <i className="ph ph-plus-circle text-2xl py-1 px-2 border border-border-grey rounded-lg"></i>
              <i onClick={cancelForm} className="ph ph-x text-xl text-low-grey"></i>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-1 px-8 pb-5">
                <h1 className='font-semibold sm:text-lg pt-3 text-scores-dark-grey'>{t('DisputeFilingInstructions')}</h1>
                <p className="text-sm text-scores-light-grey pb-2">{t('DisputeFilingNote')}</p>
                <hr />
                {activeDot === 1 ? (
                  <div className='flex flex-col gap-6 pb-3 pt-6 text-label-grey'>
                    <div className="flex flex-col gap-3  text-label-grey">
                      <div className="flex w-full flex-col gap-2 sm:gap-0 justify-between items-baseline">
                        <div className="flex flex-row gap-2 items-center">
                          <label htmlFor="ReferenceNo" className="text-sm font-medium">{t('SCORESComplaintID')}</label>
                          <InfoTooltip title={t('ProvideReferenceNumber')} />
                        </div>
                        <div className={`flex items-center gap-2 border rounded-lg w-full p-1 ${errors.primaryCaseRefId?.message ? 'border-highlight-red' : 'border-border-grey'}`}>
                          <i className="ph ph-envelope-simple text-xl text-low-grey"></i>
                          <input {...register('primaryCaseRefId')} placeholder="eg: SEBIE/MH24/ABXZ/123456" className="w-full p-2" type="text" name="primaryCaseRefId" id="ReferenceNo" onChange={handleScoreDetails} />
                        </div>
                      </div>
                      <span className="text-xs text-highlight-red">{errors.primaryCaseRefId?.message}</span>
                    </div>
                    <p className="text-sm font-semibold text-scores-blue">{t('Note')}: {t('EnterValidSCORESID')}</p>
                  </div>
                ) : (
                  <div className='flex flex-col gap-6 pb-3 pt-6 text-label-grey'>
                    <div className="flex flex-col gap-3  text-label-grey">
                      <div className="flex w-full flex-col gap-4 sm:gap-0 justify-between items-baseline">
                        <p className="font-medium text-sm pb-6">{t('UploadAnAuthorizationLetter')}</p>
                        <label htmlFor="ReferenceNo" className="text-scores-light-grey text-sm font-medium pb-2">{t('UploadAuthorizationLetter')} ({t('Optional')})</label>
                        <div className='flex items-center gap-2 border rounded-lg w-full p-2 group'>
                          <label htmlFor='upload' className="cursor-pointer">
                            <div className="flex items-center gap-4">
                              <i className="ph-bold ph-upload text-lg text-scores-light-grey group-hover:text-scores-dark-grey"></i>
                              <p className="w-full text-scores-light-grey group-hover:text-scores-dark-grey text-sm">
                                {t('UploadFile')}
                              </p>
                            </div>
                            <input aria-label={t('Choosefile')} onChange={handleFileUpload} type="file" id='upload' style={{ display: 'none' }} accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" />
                          </label>
                        </div>
                      </div>
                      <span className="text-xs text-highlight">{authLetterFile?.name}</span>
                      <span className="text-xs text-highlight-red">{errors.primaryCaseRefId?.message}</span>
                    </div>
                  </div>
                )}
                <div className="flex gap-4 items-center justify-center w-full">
                  <div onClick={() => (setActiveDot(1))} className={`w-2.5 h-2.5 transition-all duration-300 origin-left ${activeDot === 1 ? 'bg-highlight-blue' : 'bg-border-grey'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                  <div onClick={() => (setActiveDot(2))} className={`w-2.5 h-2.5 transition-all duration-300 origin-left ${activeDot === 2 ? 'bg-highlight-blue' : 'bg-border-grey'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                </div>
                {activeDot === 1 ? (
                  <div className='flex flex-row w-full pt-6 gap-4 justify-start'>
                    <button aria-label={t('cancel')} 
                    type="button"
                      onClick={cancelForm}
                      className=" hover:bg-black/[0.1] cursor-pointer border border-border-grey w-1/2 hover:text-white justify-center text-xs bg-white text-scores-light-grey px-12 py-3 rounded-xl">
                      {t('cancel')}
                    </button>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setActiveDot(2);
                      }}
                      type="button"
                      disabled={!scoreDetails?.primaryCaseRefId.trim()}
                      className="hover:bg-highlight-blue/[0.4] w-1/2 bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12"
                      aria-label={t('Proceed')}
                    >
                      <p>{t('Proceed')}</p>
                    </button>
                  </div>
                ) : (
                  <div className='flex flex-row w-full pt-6 gap-4 justify-start'>
                    <button aria-label={t('back')} 
                    type="button"
                      onClick={() => { setActiveDot(1) }}
                      className=" hover:bg-black/[0.1] cursor-pointer border border-border-grey w-1/2 hover:text-white justify-center text-xs bg-white text-scores-light-grey px-12 py-3 rounded-xl">
                      {t('Back')}
                    </button>
                    <button type="submit"
                      disabled={!scoreDetails?.primaryCaseRefId.trim()}
                      className="hover:bg-highlight-blue/[0.4] w-1/2 bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12"
                      aria-label={t('Submit')}>
                      <p>{t('Submit')}</p>
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ScoresFilingModal