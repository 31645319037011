import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { ApiController } from "../../../apiController"
import { ODRFormValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { ODRInfoBank, User } from "../../../swagger/models"
import { useTranslation } from 'react-i18next';
import { notifySuccess } from "../../../helpers/helper"
import { Tooltip } from "react-tippy"

/**
 * Component for generating API Key for ODR
 * 
 * @returns ODRInfoBankD component
 */
function ODRInfoBankD() {
    const { t } = useTranslation();
    const [odrDetail, setOdrDetails] = useState<ODRInfoBank>({})
    const [userDetail, setUserDetails] = useState<User>({})
    const [apiKey, setApiKey] = useState("")
    const currentURL = window.location.href.split('/')[3]


    const fetchApiKey = useCombinedStore((state: any) => state.fetchOdrApiKey)
    const regenerateKey = useCombinedStore((state: any) => state.regenerateAPIKey)

    const navigate = useNavigate()

    const formOptions = {
        resolver: yupResolver(ODRFormValidationSchema),
    }

    const { register, handleSubmit, formState, reset } = useForm(formOptions)
    const { errors } = formState

    /**
     * 
     * @param e 
     */
    const onInputChange = (e: any) => {
        const { name, value } = e.target
        setOdrDetails({
            ...odrDetail,
            [name]: value,
        })
    }

    /**
     * 
     */
    useEffect(() => {
        ApiController.getInstance().user$.subscribe((user: any) => {
            if (user) {
                setUserDetails(user)
                reset(user)
            }
        });
        fetchApiKey().then((key: any) => {
            setApiKey(key.data.apiKey)
        })
    }, [])


    const handleRegeneration = () => {
        regenerateKey().then((key: any) => {
            notifySuccess('API key has been regenerated successfully!')
            setApiKey(key.data.apiKey)
        })
    }


    const redirect = () => {
        navigate(`/${currentURL}/settings`)
    }

    return (
        <div className="pt-7 w-full h-fit pr-4 pl-1 pb-7">
            <div
                style={{ background: '#F4F4F4' }}
                className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
                <p className='text-xs sm:block hidden text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('ODRAPIKeyGeneration')} &gt; {t('EditData')}</p>
                <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
                <div className="bg-white w-full flex flex-col rounded-xl gap-4">
                    <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t('Back')}</Link>
                    <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
                        <i className="ph-bold ph-buildings sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                            <p className="sm:text-sm text-xs">{t('ODRAPIKeyGeneration')}</p>
                            <p className="sm:text-xs text-[10px] text-back-gray">{t('EdityourData')}</p>
                        </div>
                    </div>
                    <div className="flex flex-row w-full justify-between gap-8">
                        <div className="flex flex-col gap-2 px-7 sm:w-2/3">
                            <label htmlFor="totalConciliators" className="text-xs">{t('APIKey')} :</label>
                            <div className="flex items-center">
                                <input
                                    value={apiKey}
                                    required
                                    aria-label={t('APIKey')}
                                    type="text"
                                    name='totalConciliators'
                                    id='totalConciliators'
                                    className='rounded-xl w-full border text-xs p-2 border-solid'
                                />
                                <div className="flex items-center justify-center w-9 h-9 rounded-full border-2 border-border-grey bg-white ml-2">
                                    <Tooltip
                                        title={t('RegenrateAPIKey')}
                                        position="bottom"
                                        arrow={false}
                                        animation="shift"
                                        size="small"
                                        {...({ children: <i className='ph ph-arrow-counter-clockwise' onClick={(e) => handleRegeneration()}></i> } as any)} // Type assertion to bypass TS errors
                                    >
                                    </Tooltip>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-row gap-3 pl-7 pt-2">
                        <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
                            {t('cancel')}
                        </button>
                    </div>
                    {/* </form> */}
                    <p className="pb-3 text-xs pl-7">{" "}</p>
                </div>

            </div>
        </div>
    )
}

export default ODRInfoBankD