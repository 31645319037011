import { useCallback, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ApiController } from '../../apiController';
import { loginFormValidationSchema } from '../../helpers/validation';
import { useCombinedStore } from '../../store';
import { User, UserParent } from '../../swagger/models';
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IntermediaryLogin } from '../../swagger/models/intermediary-login';
import { notifyError, notifySuccess } from '../../helpers/helper';
import { useTranslation } from 'react-i18next';
import { encryptString } from '../common-module/helper';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import _ from "lodash";
import { Eye, EyeSlash } from 'phosphor-react';
import { filter, take } from 'rxjs';

function IntermediaryLoginComponent() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const formOptions = {
    resolver: yupResolver(loginFormValidationSchema),
  }

  const { register, handleSubmit, formState, setValue } = useForm(formOptions)
  const { errors } = formState

  const [loginRequest, setLoginRequest] = useState<IntermediaryLogin>({
    email: '',
    password: '',
    otp: '',
    parentId: '',
    isRememberme: false,
    captchaToken: ''
  })
  const [OTPRequest, setOTPRequest] = useState<IntermediaryLogin>({})
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0)
  const [passwordType, setPasswordType] = useState("password")
  const login = useCombinedStore((state: any) => state.intermediaryLoginAPI)
  const sendOTPOn = useCombinedStore((state: any) => state.intermediarySentOTP)
  const selectMIIProfiles = useCombinedStore((state: any) => state.selectMIIProfiles)
  const setIntermediaryLogin = useCombinedStore((state: any) => state.setIntermediaryLogin)

  const onInputChange = (e: any) => {
    const { name, value } = e.target
    // Update loginRequest state
    setLoginRequest((prev: IntermediaryLogin) => ({
      ...prev,
      [name]: value,
    }));

    // Update OTPRequest state
    setOTPRequest((prev: IntermediaryLogin) => ({
      ...prev,
      [name]: name === 'password' ? encryptString(value) : value,
    }));
  }

  const resendOTP = async () => {
    await ApiController.getInstance().openApi.resendOtp({
      role: 'Intermediary',
      email: loginRequest?.email,
      captchaToken: loginRequest.captchaToken,
    });
    setRefreshCaptcha((prev: number) => prev + 1);
  }

  async function onSubmit(e: any) {
    localStorage.setItem('email', loginRequest.email)
    login(OTPRequest)
    setRefreshCaptcha((prev: number) => prev + 1);
    return false
  }

  useEffect(() => {
    const navSubscription = ApiController.getInstance().UserLoggedIn$.pipe(
      filter(user => !!user),
      take(1),
    ).subscribe((userLoggedIn: User | null) => {
      if (userLoggedIn) {
        switch (userLoggedIn.userType) {
          case "Investor":
            navigate(`/investor/disputes`)
            break;
          case "ODR":
            navigate(`/odr/disputes`)
            break;
          case "SEBI":
            navigate(`/admin/disputes`)
            break;
          case "MII":
            navigate(`/mii/disputes`)
            break;
          case "Intermediary":
            navigate(`/intermediary/profiles`)
            break;
          default:
            navigate(`/login`)
            break;
        }
      }
    })
    return () => {
      navSubscription.unsubscribe()
    }
  }, [])

  const chooseProfile = () => {
    setIntermediaryLogin(loginRequest)
    if (selectMIIProfiles?.length > 1 && loginRequest.otp.length >= 6) {
      const availableProfiles = selectMIIProfiles.filter((profile: UserParent) => profile.parentId)
      const unqiueAvailableProfiles = _.uniqBy(availableProfiles, (profile: UserParent) => profile.parentId)
      if (unqiueAvailableProfiles.length > 1) {
        navigate(`/intermediary/choose-profile`)
      } else {
        login({
          ...OTPRequest,
          parentId: selectMIIProfiles[0].parentId,
        })
        setRefreshCaptcha((prev: number) => prev + 1);
        localStorage.setItem('selectProfile', JSON.stringify(selectMIIProfiles[0]))
      }
    } else if (selectMIIProfiles?.length === 1) {
      login({
        ...OTPRequest,
        parentId: selectMIIProfiles[0].parentId,
      })
      setRefreshCaptcha((prev: number) => prev + 1);
      localStorage.setItem('selectProfile', JSON.stringify(selectMIIProfiles[0]))
    } else {
      // notifyError('Something went wrong');
      login({
        ...OTPRequest,
      })
      setRefreshCaptcha((prev: number) => prev + 1);
    }
  }

  useEffect(() => {
    if (selectMIIProfiles.length > 0) {
      localStorage.setItem('selectMIIProfiles', JSON.stringify(selectMIIProfiles))
    }
  }, [selectMIIProfiles])

  const changeLanguage = (langauge: string) => {
    localStorage.setItem('selectedLanguage', langauge)
    i18n.changeLanguage(langauge)
  }

  const onVerify = useCallback((token: string) => {
    console.log('onVerify', token)
    setLoginRequest({
      ...loginRequest,
      captchaToken: token
    })
    setOTPRequest({
      ...OTPRequest,
      captchaToken: token
    })
    console.log(loginRequest.email, OTPRequest.email)
  }, [sendOTPOn, refreshCaptcha]);

  // Use useEffect to set up the interval
  useEffect(() => {
    if (localStorage.getItem('email')) {
      setLoginRequest({
        ...loginRequest,
        email: localStorage.getItem('email'),
      })
      setOTPRequest({
        ...OTPRequest,
        email: localStorage.getItem('email'),
      })
    }
  }, []); // Add dependencies accordingly

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  return (
    <div className="flex flex-row justify-between pt-7 px-10 lg:pl-24 pb-7 w-full h-screen">
      <div className="flex flex-col h-full w-full lg:w-1/3 sm:pt-20 pt-10 justify-between">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
        <div>
          <div className='flex flex-row justify-between items-start'>
            <NavLink to="/login">
              <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
            </NavLink>
            <div className='hidden lg:block'>
              <div className='grid grid-cols-2 gap-2'>
                <Link to="/mii/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('MII')} {t('login')}</Link>
                <Link to="/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('Investor')} {t('login')}</Link>
                <Link to="/odr/login" className="text-xs pt-2 w-20 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap pr-7">{t('ODRInstitution')} {t('login')}</Link>
              </div>
            </div>

          </div>
          <div className="pt-24">
            <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
              <p className="font-bold text-highlight-blue">{t('DisputeDataDashboard')}</p>
              <p className="text-highlight-blue">{t('Click')} <NavLink to="/dashboard" className='hover:underline' style={{ color: '#306AFF' }}>{t('here')} </NavLink>{t('disputeDataDashboardNote')}
              </p>
            </div>
          </div>
          <h1 className='pt-6 text-3xl text-highlight-blue font-bold'>{t('MarketParticipant')} {t('login')} </h1>
          <form className="flex flex-col pt-12 gap-2" onSubmit={handleSubmit(onSubmit)} autoComplete='false'>
            <label htmlFor="email" className="text-xs">{t('email')}</label>
            <input required aria-label={t('email')} type="text" id='email'
              autoComplete="off" // Try "off", "new-password", or "nope"
              {...register('email')}
              onChange={onInputChange}
              value={loginRequest?.email}
              className={`rounded-xl border p-2 border-solid ${errors.email?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
            <span className="text-xs text-highlight-red">{errors.email?.message}</span>
            <div className='flex flex-row justify-between'>
              <label htmlFor="password" className="text-xs pt-4">{t('password')}</label>
              <a href='/forgot-password?role=Intermediary' aria-label='Forgot Password' className='text-xs pt-4' style={{ color: '#306AFF' }}>{t('forgotPassword')}?</a>
            </div>
            <div className='flex flex-row justify-between items-center border rounded-lg'>
              <input required type={passwordType} id='password'
                {...register('password')}
                onChange={onInputChange}
                value={loginRequest?.password}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('password')} className={`rounded-xl p-2  w-full mr-1 ${errors.password?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <button onClick={togglePassword} type='button' className='px-3'>
                {passwordType === 'password' ? <Eye size={20} weight="thin" /> : <EyeSlash size={20} weight="thin" />}
              </button>
            </div>
            <span className="text-xs text-highlight-red">{errors.password?.message}</span>
            {sendOTPOn &&
              <>
                <label htmlFor="password" className="text-xs">{t('OTP')}</label>
                <input required type={passwordType} id='OTP'
                  {...register('otp')} onChange={onInputChange}
                  value={loginRequest?.otp}
                  aria-label={t('OTP')} className={`rounded-lg border p-2 border-solid ${errors.otp?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.otp?.message}</span>
                <button aria-label='Login' type='button' onClick={chooseProfile} className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
                  {t('login')}
                </button>
                <button aria-label='ResendOTP' type="button" onClick={(e) => { resendOTP() }} className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
                  {t('resendOTP')}
                </button>
                <div className="flex flex-row gap-2 pt-3  w-full items-start">
                  <input type="checkbox" checked={loginRequest?.isRememberme} onChange={() => setLoginRequest({
                    ...loginRequest,
                    isRememberme: !loginRequest?.isRememberme
                  })} />
                  <p className="text-xs text-label-grey">{t('keepMeLoggedIn')}</p>
                </div>
              </>
            }
            {!sendOTPOn &&
              <>
                <button aria-label='Login' type='submit' className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
                  {t('login')}
                </button>
                <div className="flex flex-row gap-2 pt-3  w-full items-start">
                  <input type="checkbox" checked={loginRequest?.isRememberme} onChange={() => setLoginRequest({
                    ...loginRequest,
                    isRememberme: !loginRequest?.isRememberme
                  })} />
                  <p className="text-xs text-label-grey">{t('keepMeLoggedIn')}</p>
                </div>
              </>
            }
          </form>
        </div>
        <div className='flex flex-col justify-between'>
          <p className='text-xs py-10 text-label-grey'>
            {t('leaveUsFeedback')}
            <a href="https://forms.gle/P4yS5cGYoxV6GnsD9" target='_blank' className='hover:underline' style={{ color: '#306AFF' }}> {t('here')}. <br></br>
            </a>
            {t('IncaseofanyIssues')}
            <a href="https://help.smartodr.in/" target='_blank' className='hover:underline' style={{ color: '#306AFF' }}> {t('helpCenter')}</a>
          </p>
        </div>
        <div className='flex flex-row justify-between'>
          <div className='text-xs py-8 text-label-grey flex flex-row flex-wrap gap-2'>
            <p>{t('SmartODROfferedIn')}: </p>
            <p onClick={() => changeLanguage('en')} className='hover:text-link-blue cursor-pointer'>English</p>
            <p onClick={() => changeLanguage('hi')} className='hover:text-link-blue cursor-pointer'>Hindi</p>
            <p onClick={() => changeLanguage('gu')} className='hover:text-link-blue cursor-pointer'>Gujarati</p>
            <p onClick={() => changeLanguage('te')} className='hover:text-link-blue cursor-pointer'>Telugu</p>
            <p onClick={() => changeLanguage('ta')} className='hover:text-link-blue cursor-pointer'>Tamil</p>
            <p onClick={() => changeLanguage('ma')} className='hover:text-link-blue cursor-pointer'>Marathi</p>
            <p onClick={() => changeLanguage('be')} className='hover:text-link-blue cursor-pointer'>Bengali</p>
            <p onClick={() => changeLanguage('kn')} className='hover:text-link-blue cursor-pointer'>Kannada</p>
            <p onClick={() => changeLanguage('ml')} className='hover:text-link-blue cursor-pointer'>Malyalam</p>
          </div>
        </div>
        <div className='grid grid-cols-2 lg:hidden pb-14'>
          <Link to="/odr/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('ODRInstitution')} {t('login')}</Link>
          <Link to="/mii/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('MII')}{t('login')}</Link>
          <Link to="/login" className="text-xs pt-2 text-highlight-blue hover:text-highlight-blue/50">{t('Investor')} {t('login')}</Link>
        </div>
      </div>
      <div className='hidden lg:flex w-1/2 pl-8 justify-end'>
        <div className='hidden lg:block fixed h-full w-fit mb-8 pl-8 py-8 top-0 right-0 rounded-2xl'>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
        </div>
      </div>
    </div>
  )
}

export default IntermediaryLoginComponent